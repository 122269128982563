<template>
  <div class="body">
    <div class="top">
      <div class="top1" @click="a()">
        Trang chủ  /  KQBD
        <br>
        <br>

        <h5>
          KQBD CỦA TẤT CẢ CÁC GIẢI ĐẤU

        </h5>
      </div>
      <div class="container">
        <div class="containerTop">
          <div style="font-size: 16px" v-for="(item,index) in countries" :key="index">
            <div  style="font-size: 13px"
                  :class="{ 'item': true, 'green': selectedIndex === index }"
                  @click="changeCountry(item.country_id,index)"
                  class="item" v-if="countryId.includes(item.country_id)">{{ item.country_name2 }}</div>

          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 100 }"
               @click="changeLeague('3',100)"
               class="item">C1
          </div>

          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 101 }"
               @click="changeLeague('4',101)"
               class="item">C2
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 102 }"
               @click="changeLeague('683',102)"
               class="item">C3
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 103 }"
               @click="changeLeague('339',103)"
               class="item">V LEAGUE
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 104 }"
               @click="changeLeague('401',104)"
               class="item">Cup
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 105 }"
               @click="changeLeague('470',105)"
               class="item">Super Cup
          </div>

        </div>

        </div>
      <div style="height: 20px"></div>
      <div class="container">
        <div class="containerTop">
          <div :class="{ 'item': true, 'green': dateIndex === 1 }" @click="changeDate(7,0,1)" class="item">Tuần trước</div>
          <div :class="{ 'item': true, 'green': dateIndex === 2 }" @click="changeDate(0,0,2)" class="item">{{ today }}</div>
          <div :class="{ 'item': true, 'green': dateIndex === 3 }" @click="changeDate(1,1,3)" class="item">{{ yesterday }}</div>
          <div :class="{ 'item': true, 'green': dateIndex === 4 }" @click="changeDate(2,2,4)" class="item">{{ twoDaysAgo }}</div>
          <div :class="{ 'item': true, 'green': dateIndex === 5 }" @click="changeDate(3,3,5)" class="item">{{ threeDaysAgo }}</div>
          <div :class="{ 'item': true, 'green': dateIndex === 6 }" @click="changeDate(4,4,6)" class="item">{{ fourDaysAgo }}</div>
          <div :class="{ 'item': true, 'green': dateIndex === 7 }" @click="changeDate(5,5,7)" class="item">{{ fiveDaysAgo }}</div>
          <div :class="{ 'item': true, 'green': dateIndex === 8 }" @click="changeDate(6,6,8)" class="item">{{ sixDaysAgo }}</div>


        </div>
      </div>

      <div id="loading" class="loading" v-if="loading"></div>
      <div  @click="a(item.match)" class="matchBox" v-for="(item, index) in list" :key="index">
        <div v-if="Array.isArray(item.match)" >
          <div class="matchBox-title">
            <div class="matchBox-title-left">
              <img :src=item.league_logo style="width: 30px"> <span>{{item.league_name}}</span>
            </div>
            <div class="matchBox-title-center">
              <div>Chủ nhà</div>
              <div>Tỷ số</div>
              <div>Khách</div>
            </div>
            <div class="matchBox-title-right">
              <div>HT</div>
<!--              <div>8</div>-->
            </div>
          </div>
          <div v-for="(match, index) in item.match" :key="index" class="matchBox-container" >
            <div class="matchBox-container-left">{{match.match_date
              }}<br> {{match.match_time}}</div>
            <div class="matchBox-container-center">
              <div style="margin-right: 2%; align-items: center; width: 40%;justify-content: flex-start; display: flex">  <img :src=match.team_home_badge style="width: 30px">&nbsp; <span>{{match.match_hometeam_name}}</span></div>
              <div style="background-color: #0A7FD0" >{{match.match_hometeam_score}}&nbsp;:&nbsp;{{match.match_awayteam_score}}</div>
               <div style="align-items: center;margin-left: 3%;width: 40%;justify-content: flex-end; display: flex"> <span>{{match.match_awayteam_name}}</span> &nbsp;<img :src=match.team_away_badge style="width: 30px"> </div>
            </div>
            <div class="matchBox-container-right">
              <div style=" background-color: #8B0000 ;display: flex;justify-content: center" >{{match.match_hometeam_halftime_score}}&nbsp;:&nbsp;{{match.match_awayteam_halftime_score}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {

    return {
      countryId:[6,44,4,5,3,95,17,92,64,32,68,14,27,78,82,39,40,23,87,106,108,59,42],
      date1:'',
      date2:'',
      country:44,
      countries:[],
      loading:false,
      currentDate: new Date(),
      league_name: [],
      tomorrow: [],
      list: [],
      chunkSize: 10, // 每次加载的数量
      loadedItems: 10, // 已加载的数量
      selectedIndex: 47,
     dateIndex: 1

    };
  },
  computed: {
    today() {
      return this.formatDate(0);
    },
    yesterday() {
      return this.formatDate(1);
    },
    twoDaysAgo() {
      return this.formatDate(2);
    },
    threeDaysAgo() {
      return this.formatDate(3);
    },
    fourDaysAgo() {
      return this.formatDate(4);
    },
    fiveDaysAgo() {
      return this.formatDate(5);
    },
    sixDaysAgo() {
      return this.formatDate(6);
    },
    displayedData() { // 计算属性用于返回要显示在页面上的颜色数组
      return this.total.slice(0, this.loadedItems);
    }
  },
  created() {
    this.countryId = this.countryId.map(number => number.toString());

    this.changeDate(7,0,1)
    this.fetchFootballCountries()
  },
  methods: {
    formatDate(offset) {
      const targetDate = new Date(this.currentDate);
      targetDate.setDate(targetDate.getDate() - offset);
      const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/New_York'
      });
      return formatter.format(targetDate);
    },
    changeDate(date1,date2,index){
      this.date1=new Date(new Date().setDate(new Date().getDate() - date1)).toISOString().slice(0, 10);
      this.date2=new Date(new Date().setDate(new Date().getDate() - date2)).toISOString().slice(0, 10);
      this.dateIndex=index

      this.fetchFootballTotal()
    },
    changeCountry(country,index){
      console.log(index);
      this.selectedIndex = index;
      this.country=country
      this.fetchFootballTotal()
    },


    changeLeague(leagueId,index){
      this.selectedIndex = index;

      this.fetchFootballLeague(leagueId)
    },
    fetchFootballLeague(league_id){
      this.list=[{match:{}}]
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd'
      axios.get(`https://apiv3.apifootball.com/?action=get_events&from=${this.date1}&to=${this.date2}&league_id=${league_id}&APIkey=${APIkey}`)
          .then(response => {
            console.log(response)
            this.list[0].league_name=response.data[0].league_name
            this.list[0].league_logo=response.data[0].league_logo
this.list[0].match=response.data
            console.log(this.list)
          })
          .catch(error => {
            console.error(error);
          });
    },
    a(b){
      console.log(b)
      console.log(this.list)

    },
    fetchFootballCountries() {
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      axios.get(`https://apiv3.apifootball.com/?action=get_countries&APIkey=${APIkey}`)
          .then(response => {
            // 假设你已经有了国家名的映射关系

            const countryMap = {
              "England": "ANH",
              "Spain": "TBN",
              "Germany": "ĐỨC",
              "Italy": "Ý",
              "France": "PHÁP",
              "Champions League": "C1",
              "Europa League": "C2",
              "Conference League": "C3",
              "Russia": "NGA",
              "V-League": "V LEAGUE",
              "Africa Cup of Nations": "CÚP CHÂU PHI",
              "Australia": "ÚC",
              "Portugal": "BĐN",
              "Major League Soccer": "MLS",
              "Japan": "NHẬT",
              "China": "CHINA",
              "Korea Republic": "HÀN",
              "Argentina": "ARGENTINA",
              "Brazil": "BRAZIL",
              "Mexico": "MEXICO",
              "Denmark": "ĐAN MẠCH",
              "Norway": "NA UY",
              "Belgium": "BỈ",
              "Switzerland": "THỤY SĨ",
              "Egypt": "AI CẬP",
              "FA Cup": "FA CUP",
              "Thailand": "THÁI",
              "Indonesia": "INDO",
              "Bundesliga": "BUNDESLIGA 2",

              // ... 其他国家的映射关系
            };

// 假设接口返回的数组为countries
            const mappedCountries = response.data.map(country => {
              return {
                country_id: country.country_id,
                country_name: country.country_name,
                country_name2: countryMap[country.country_name] || "", // 使用映射关系添加新字段
                // 其他字段
              };
            });
            console.log('aaaaa',mappedCountries)
            this.countries = mappedCountries;

          })
          .catch(error => {
            console.error(error);
          });
    },
    loadMore() { // 加载更多按钮点击事件处理函数
      if (this.loadedItems >= this.total.length) return; // 如果已加载的数量等于或超过原始数据数组长度，则停止加载
      this.loadedItems += this.chunkSize; // 增加已加载数量
      if (this.loadedItems > this.total.length) {
        this.loadedItems = this.total.length;
      }   // 如果超过了原始数据数组长度，则将已加载数量设为数组长度，避免超出范围

    },

    async fetchFootballTotal() {
      let success = false;
      while (!success) {
        try {
          this.loading=true
          this.list = []
          const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
          const response = await axios.get(`https://apiv3.apifootball.com/?action=get_leagues&country_id=${this.country}&APIkey=${APIkey}`)
          const list = response.data;

          await Promise.all(list.map(async item => {
            const league_id = item.league_id;
            const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&from=${this.date1}&to=${this.date2}&league_id=${league_id}&APIkey=${APIkey}`);
            item.match = detailsResponse.data;

          }));
          this.list = list;
          console.log('All data with details:', this.list);
          success = true;
          this.loading=false
          // 在这里进行处理和渲染详细数据
        } catch (error) {
          console.error(error);
          // 如果接口调用失败，则等待一段时间后重新调用
          await new Promise(resolve => setTimeout(resolve, 1000)); // 等待1秒
        }
      }
    }
  }
};

</script>
<style scoped lang="scss">
@media (min-width: 700px) {
  .loading {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -20px; /* 将加载动画水平居中 */
    margin-top: -20px; /* 将加载动画垂直居中 */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .body {
    //height: 10000px;
    background-color: #232324;
    margin-top: -1%;
  }
  .green {
    background-color: green;
  }
  .top {
    .matchBox {
      .matchBox-title {
        margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 50px;

        .matchBox-title-left {
          margin-left: 1%;
          width: 25%;
          justify-content: flex-start;
        }

        .matchBox-title-center {
          margin-left: 1%;
          width: 50%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 33%;
          }
        }

        .matchBox-title-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 25%;
        }
      }

      .matchBox-container {
        margin-left: -1%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;

        height: 65px;

        .matchBox-container-left {
          margin-left: 1%;
          width: 25%;
        }

        .matchBox-container-center {
          margin-left: 1%;
          width: 50%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 20%;
            height: 30px;
          }
        }

        .matchBox-container-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 25%;
          div{
            width: 30%;
          }
        }
      }
    }

    width: 85%;
    margin: auto;
    left: 50%;

    .container {
      margin-top: 2%;
      background: #1c1e1f;
      margin-left: -1%;
max-width: 100%;
      width: 100%;
      //border: blue solid 1px;

      .containerTop {
        height: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        display: inline-flex; /* 使用inline-flex布局 */
        .item {
          font-size: 16px; /* 设置更大的字体大小 */
          cursor: pointer;
          margin: 7px; /* 设置一些外边距 */
          //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
          text-align: center; /* 居中文本 */

        }
      }
    }
  }

  .top1 {
    color: white;
    padding-top: 3%;

  }


  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}
@media (max-width:700px ) {
  .loading {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -20px; /* 将加载动画水平居中 */
    margin-top: -20px; /* 将加载动画垂直居中 */
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .body {
    //height: 10000px;
    background-color: #232324;
    margin-top: -1%;
  }
  .green {
    background-color: green;
  }
  .top {
    .matchBox {
      .matchBox-title {
        margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 50px;
font-size: 12px;
        .matchBox-title-left {
          margin-left: 1%;
          width: 20%;
          justify-content: flex-start;
          font-size: 12px;
          img{
            display: none;
          }
        }

        .matchBox-title-center {
          font-size: 12px;
          margin-left: 1%;
          width: 70%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 25%;
          }
        }

        .matchBox-title-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 10%;
        }
      }

      .matchBox-container {
        margin-left: -1%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        height: 65px;

        .matchBox-container-left {
          font-size: 10px;
          margin-left: 1%;
          width: 20%;
        }

        .matchBox-container-center {
          margin-left: 1%;
          width: 70%;
          display: flex;
          justify-content: center;

          div {
            height: 25px;
            display: flex;
            justify-content: center;
            width: 15%;
          }
        }

        .matchBox-container-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 10%;
          div{
            width: 60%;
          }
        }
      }
    }

    width: 95%;
    margin: auto;
    left: 50%;

    .container {
      margin-top: 2%;
      background: #1c1e1f;
      margin-left: -1%;
max-width: 100%;
      width: 100%;
      //border: blue solid 1px;

      .containerTop {
        height: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        display: inline-flex; /* 使用inline-flex布局 */
        .item {

          cursor: pointer;
          margin: 7px; /* 设置一些外边距 */
          //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
          text-align: center; /* 居中文本 */

        }
      }
    }
  }

  .top1 {
    color: white;
    padding-top: 3%;

  }


  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}

</style>
