<template>
  <div id="app">
    <router-view></router-view>
  </div>

</template>

<script>

export default {
  name: 'App',
  components: {
    // 在这里注册全局组件
  }
};
</script>

<style lang="scss">

</style>