<template>

  <div class="body">
    <div style="height: 30px"></div>
    <div class="container">

      <ul class="list-filter">
        <li>

          <div @click="fetchFootballLive()" class="now">
            <svg width="50" height="18" viewBox="0 0 50 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M11.962 14V4.77H13.756V12.427H18.462V14H11.962ZM19.5538 14V4.77H21.3478V14H19.5538ZM24.2225 4.77L26.7185 11.868L29.1885 4.77H31.0735L27.5765 14H25.8475L22.3375 4.77H24.2225ZM38.4677 12.427V14H32.0587V4.77H38.3507V6.343H33.8527V8.566H37.7397V10.022H33.8527V12.427H38.4677Z"
                  fill="white"></path>
              <path
                  d="M5.49408 14.1756C5.63601 14.3189 5.80017 14.402 5.98657 14.4248C6.17297 14.4477 6.3378 14.3937 6.48108 14.2627C6.54728 14.1862 6.6108 14.0988 6.67162 14.0003C6.73245 13.9019 6.76313 13.7978 6.76367 13.6881C6.764 13.6223 6.74792 13.5454 6.71543 13.4575C6.68295 13.3695 6.65034 13.309 6.61759 13.276C6.07221 12.626 5.66979 11.9108 5.41032 11.1306C5.13981 10.3613 5.00653 9.57617 5.01047 8.77528C5.01441 7.97438 5.15543 7.18515 5.43354 6.40755C5.70063 5.64086 6.11003 4.93523 6.66177 4.29063C6.8051 4.14871 6.86629 3.97621 6.84535 3.77313C6.8244 3.57006 6.74289 3.41332 6.6008 3.30291C6.45888 3.15958 6.28638 3.09839 6.08331 3.11933C5.88024 3.14028 5.72349 3.22179 5.61308 3.36388C4.93996 4.15051 4.43654 5.00927 4.10282 5.9402C3.75808 6.88205 3.58334 7.83295 3.57862 8.79292C3.5739 9.7529 3.72836 10.6944 4.04199 11.6176C4.36653 12.5517 4.85056 13.4044 5.49408 14.1756ZM3.02467 0.964876C2.04268 2.0901 1.3015 3.33169 0.801108 4.68969C0.300715 6.04768 0.0470433 7.4343 0.0400831 8.84958C0.0331229 10.2649 0.273145 11.6539 0.760156 13.0167C1.24711 14.3906 1.97604 15.6394 2.94697 16.7632C3.0889 16.9066 3.25306 16.9897 3.43946 17.0125C3.62586 17.0354 3.79072 16.9759 3.93405 16.8339C4.04424 16.7357 4.11874 16.6456 4.15755 16.5635C4.19635 16.4814 4.21602 16.3855 4.21656 16.2758C4.21689 16.21 4.20356 16.1303 4.17659 16.037C4.14963 15.9436 4.11439 15.8584 4.07088 15.7814C3.1981 14.7787 2.54526 13.6728 2.11235 12.4639C1.67939 11.2658 1.46597 10.0442 1.47209 8.79902C1.47822 7.55379 1.70364 6.33435 2.14837 5.14065C2.59309 3.94696 3.25669 2.86407 4.13919 1.89195C4.28251 1.75003 4.34366 1.58576 4.32264 1.39914C4.30161 1.21253 4.22014 1.04756 4.07822 0.904229C3.90338 0.760741 3.71995 0.691269 3.52793 0.69581C3.33591 0.700353 3.16816 0.790041 3.02467 0.964876Z"
                  fill="#3DD269"></path>
              <path
                  d="M44.5059 14.1756C44.364 14.3189 44.1998 14.402 44.0134 14.4248C43.827 14.4477 43.6622 14.3937 43.5189 14.2627C43.4527 14.1862 43.3892 14.0988 43.3284 14.0003C43.2675 13.9019 43.2369 13.7978 43.2363 13.6881C43.236 13.6223 43.2521 13.5454 43.2846 13.4575C43.317 13.3695 43.3497 13.309 43.3824 13.276C43.9278 12.626 44.3302 11.9108 44.5897 11.1306C44.8602 10.3613 44.9935 9.57617 44.9895 8.77528C44.9856 7.97438 44.8446 7.18515 44.5665 6.40755C44.2994 5.64086 43.89 4.93523 43.3382 4.29063C43.1949 4.14871 43.1337 3.97621 43.1547 3.77313C43.1756 3.57006 43.2571 3.41332 43.3992 3.30291C43.5411 3.15958 43.7136 3.09839 43.9167 3.11933C44.1198 3.14028 44.2765 3.22179 44.3869 3.36388C45.06 4.15051 45.5635 5.00927 45.8972 5.9402C46.2419 6.88205 46.4167 7.83295 46.4214 8.79292C46.4261 9.7529 46.2716 10.6944 45.958 11.6176C45.6335 12.5517 45.1494 13.4044 44.5059 14.1756ZM46.9753 0.964876C47.9573 2.0901 48.6985 3.33169 49.1989 4.68969C49.6993 6.04768 49.953 7.4343 49.9599 8.84958C49.9669 10.2649 49.7269 11.6539 49.2398 13.0167C48.7529 14.3906 48.024 15.6394 47.053 16.7632C46.9111 16.9066 46.7469 16.9897 46.5605 17.0125C46.3741 17.0354 46.2093 16.9759 46.066 16.8339C45.9558 16.7357 45.8813 16.6456 45.8425 16.5635C45.8036 16.4814 45.784 16.3855 45.7834 16.2758C45.7831 16.21 45.7964 16.1303 45.8234 16.037C45.8504 15.9436 45.8856 15.8584 45.9291 15.7814C46.8019 14.7787 47.4547 13.6728 47.8876 12.4639C48.3206 11.2658 48.534 10.0442 48.5279 8.79902C48.5218 7.55379 48.2964 6.33435 47.8516 5.14065C47.4069 3.94696 46.7433 2.86407 45.8608 1.89195C45.7175 1.75003 45.6563 1.58576 45.6774 1.39914C45.6984 1.21253 45.7799 1.04755 45.9218 0.904229C46.0966 0.760741 46.28 0.691269 46.4721 0.69581C46.6641 0.700352 46.8318 0.790039 46.9753 0.964876Z"
                  fill="#3DD369"></path>
            </svg>
            <span class="num"></span>
          </div>
        </li>
        <li>
          <div @click="fetchFootballYesterday" class="today">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_350_166)">
                <path
                    d="M0.946289 13.3408C0.946289 13.5269 1.02022 13.7054 1.15183 13.837C1.28344 13.9686 1.46193 14.0425 1.64804 14.0425H13.344C13.5301 14.0425 13.7086 13.9686 13.8402 13.837C13.9718 13.7054 14.0457 13.5269 14.0457 13.3408V5.15363H0.946289V13.3408ZM3.28548 6.35655C3.28548 6.28567 3.31363 6.21772 3.36375 6.16757C3.41387 6.11746 3.48185 6.0893 3.55273 6.0893H6.76091C6.83179 6.0893 6.89977 6.11746 6.94989 6.16757C7.00001 6.21772 7.02817 6.28567 7.02817 6.35655V9.56474C7.02817 9.63561 7.00001 9.7036 6.94989 9.75371C6.89977 9.80383 6.83179 9.83199 6.76091 9.83199H3.55273C3.48185 9.83199 3.41387 9.80383 3.36375 9.75371C3.31363 9.7036 3.28548 9.63561 3.28548 9.56474V6.35655Z"
                    fill="#5EB24A"></path>
                <path
                    d="M13.344 1.87879H11.7089V0.943115H10.3054V1.87879H4.68665V0.943115H3.28314V1.87879H1.64804C1.55624 1.87802 1.46519 1.89542 1.38014 1.92999C1.29509 1.96456 1.21772 2.01562 1.15249 2.08023C1.08726 2.14483 1.03546 2.2217 1.00007 2.30642C0.964681 2.39113 0.946403 2.48201 0.946289 2.57382V4.21796H14.0457V2.57382C14.0456 2.48201 14.0273 2.39113 13.9919 2.30642C13.9565 2.2217 13.9047 2.14483 13.8395 2.08023C13.7743 2.01562 13.6969 1.96456 13.6119 1.92999C13.5268 1.89542 13.4358 1.87802 13.344 1.87879Z"
                    fill="#FFE400"></path>
              </g>
              <defs>
                <clipPath id="clip0_350_166">
                  <rect width="15" height="15" fill="white"></rect>
                </clipPath>
              </defs>
            </svg> &nbsp;
            Hôm qua <span class="num"></span>
          </div>
        </li>
        <li>
          <div @click="fetchFootballToday" class="today">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_350_166)">
                <path
                    d="M0.946289 13.3408C0.946289 13.5269 1.02022 13.7054 1.15183 13.837C1.28344 13.9686 1.46193 14.0425 1.64804 14.0425H13.344C13.5301 14.0425 13.7086 13.9686 13.8402 13.837C13.9718 13.7054 14.0457 13.5269 14.0457 13.3408V5.15363H0.946289V13.3408ZM3.28548 6.35655C3.28548 6.28567 3.31363 6.21772 3.36375 6.16757C3.41387 6.11746 3.48185 6.0893 3.55273 6.0893H6.76091C6.83179 6.0893 6.89977 6.11746 6.94989 6.16757C7.00001 6.21772 7.02817 6.28567 7.02817 6.35655V9.56474C7.02817 9.63561 7.00001 9.7036 6.94989 9.75371C6.89977 9.80383 6.83179 9.83199 6.76091 9.83199H3.55273C3.48185 9.83199 3.41387 9.80383 3.36375 9.75371C3.31363 9.7036 3.28548 9.63561 3.28548 9.56474V6.35655Z"
                    fill="#5EB24A"></path>
                <path
                    d="M13.344 1.87879H11.7089V0.943115H10.3054V1.87879H4.68665V0.943115H3.28314V1.87879H1.64804C1.55624 1.87802 1.46519 1.89542 1.38014 1.92999C1.29509 1.96456 1.21772 2.01562 1.15249 2.08023C1.08726 2.14483 1.03546 2.2217 1.00007 2.30642C0.964681 2.39113 0.946403 2.48201 0.946289 2.57382V4.21796H14.0457V2.57382C14.0456 2.48201 14.0273 2.39113 13.9919 2.30642C13.9565 2.2217 13.9047 2.14483 13.8395 2.08023C13.7743 2.01562 13.6969 1.96456 13.6119 1.92999C13.5268 1.89542 13.4358 1.87802 13.344 1.87879Z"
                    fill="#FFE400"></path>
              </g>
              <defs>
                <clipPath id="clip0_350_166">
                  <rect width="15" height="15" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>&nbsp;
            Hôm nay <span class="num"></span>
          </div>
        </li>
        <li>
          <div @click="fetchFootballTomorrow" class="tomorrow">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_350_166)">
                <path
                    d="M0.946289 13.3408C0.946289 13.5269 1.02022 13.7054 1.15183 13.837C1.28344 13.9686 1.46193 14.0425 1.64804 14.0425H13.344C13.5301 14.0425 13.7086 13.9686 13.8402 13.837C13.9718 13.7054 14.0457 13.5269 14.0457 13.3408V5.15363H0.946289V13.3408ZM3.28548 6.35655C3.28548 6.28567 3.31363 6.21772 3.36375 6.16757C3.41387 6.11746 3.48185 6.0893 3.55273 6.0893H6.76091C6.83179 6.0893 6.89977 6.11746 6.94989 6.16757C7.00001 6.21772 7.02817 6.28567 7.02817 6.35655V9.56474C7.02817 9.63561 7.00001 9.7036 6.94989 9.75371C6.89977 9.80383 6.83179 9.83199 6.76091 9.83199H3.55273C3.48185 9.83199 3.41387 9.80383 3.36375 9.75371C3.31363 9.7036 3.28548 9.63561 3.28548 9.56474V6.35655Z"
                    fill="#5EB24A"></path>
                <path
                    d="M13.344 1.87879H11.7089V0.943115H10.3054V1.87879H4.68665V0.943115H3.28314V1.87879H1.64804C1.55624 1.87802 1.46519 1.89542 1.38014 1.92999C1.29509 1.96456 1.21772 2.01562 1.15249 2.08023C1.08726 2.14483 1.03546 2.2217 1.00007 2.30642C0.964681 2.39113 0.946403 2.48201 0.946289 2.57382V4.21796H14.0457V2.57382C14.0456 2.48201 14.0273 2.39113 13.9919 2.30642C13.9565 2.2217 13.9047 2.14483 13.8395 2.08023C13.7743 2.01562 13.6969 1.96456 13.6119 1.92999C13.5268 1.89542 13.4358 1.87802 13.344 1.87879Z"
                    fill="#FFE400"></path>
              </g>
              <defs>
                <clipPath id="clip0_350_166">
                  <rect width="15" height="15" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>&nbsp;
            Ngày mai <span class="num"></span>
          </div>
        </li>
        <li>
          <div @click="fetchFootballTotal" class="active all" data-filter="all">Tất cả <span class="num"></span></div>
        </li>
      </ul>
      <div class="grid-matches row">
        <div @click="goToDetailPage(item.match_id)" class="grid-matches__item mb-3"
             v-for="(item, index) in displayedData" :key="index">
          <div class="grid-match  grid-match--is-hot ">
            <div class="grid-match__header">
              <div class="grid-match__league">
                <img :src=item.league_logo
                     width="24px" height="24px" alt="">
                <span class="text-ellipsis" data-attr="UEFA EURO">{{ item.league_name }}</span>
              </div>
              <div v-if="item.match_live==='1'||item.match_status==='Finished'"
                   class="green_title grid-match__time grid-match__status--normal"
              >
                <div v-if="item.match_live==='1'" style=" display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;" class="icon-haflt" data-fid="4jwq2ghexy3xm0v">

                  Đang diễn ra
                </div>
                <div v-else-if="item.match_status==='Finished'" style=" display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1px;" class="icon-haflt" data-fid="4jwq2ghexy3xm0v">

                  Kết thúc
                </div>
              </div>
              <div class="grid-match__date">
                {{ item.match_time }}
                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="2"
                     viewBox="0 0 2 2" fill="none">
                  <circle cx="1" cy="1" r="1" fill="#C2E598"/>
                </svg>
                {{ item.match_date }}
              </div>
            </div>
            <div class="grid-match__body">
              <div class="grid-match__team grid-match__team-home team team--home">
                <img :src=item.team_home_badge
                     alt="Tây Ban Nha"
                     class="grid-match__team-logo grid-match__team-home-logo team__logo lazy">
                <span class="grid-match__team--name grid-match__team--home-name">{{ item.match_hometeam_name }}</span>
              </div>

              <div v-if="item.match_live==='1'||item.match_status==='Finished'"
                   class="grid-match__status"
                   style="
                   font-size: 18px;
                   background: linear-gradient(to bottom, blue, #3366ff);
    width: 120px;
    height: 35px;
    border-radius: 100px / 100px !important;
    margin-top: 5px;
    margin-left: 13px;
    margin-right: 13px;
"

              >
                <div style=" display: flex;
                border-radius: 100px / 100px;
    justify-content: center;
    align-items: center;
    margin-top: 1px;" class="icon-haflt" data-fid="4jwq2ghexy3xm0v">

                  {{ item.match_hometeam_score }} &nbsp;: &nbsp;{{
                    item.match_awayteam_score
                  }}
                </div>
              </div>

              <div v-else class="grid-match__status" data-fid="4jwq2ghexy3xm0v">
                <span class="grid-match__vs">vs</span>
              </div>
              <div class="grid-match__team grid-match__team-away team team--away">
                <img :src=item.team_away_badge
                     alt="Pháp"
                     class="grid-match__team-logo grid-match__team-away-logo team__logo lazy">
                <span class="grid-match__team--name grid-match__team--away-name">{{ item.match_awayteam_name }}</span>
              </div>
            </div>

            <div class="grid-match__footer d-flex justify-content-between gap-3 align-items-center">
              <div class="grid-match__footer-left d-flex gap-3">
                <div class="grid-match--flag d-flex">
                  <div class="grid-match__goal-scoring d-flex">
                    <span>HT</span>
                    <span class="grid-match__half-court halfCourt">{{ item.match_hometeam_halftime_score }} &nbsp;: &nbsp;{{
                        item.match_awayteam_halftime_score
                      }}</span>
                  </div>
                </div>
              </div>

              <div class="grid-match__footer-right d-flex align-items-center gap-3 justify-content-end">
                <div class="grid-match__odds d-flex flex-column">


                  <div v-if="item['ah+1.5_1']" class="grid-match__odds-item grey d-flex">
                    <p>
                      {{ item['ah+1.5_1'] }}
                    </p>
                    <p>
                      1.5
                    </p>
                    <p>
                      {{ item['ah+1.5_2'] }}
                    </p>
                  </div>
                  <div v-else-if="item['ah+2.5_1']" class="grid-match__odds-item grey d-flex">
                    <p>
                      {{ item['ah+2.5_1'] }}
                    </p>
                    <p>
                      2.5
                    </p>
                    <p>
                      {{ item['ah+2.5_2'] }}
                    </p>
                  </div>
                  <div v-else-if="item['ah+3.5_1']" class="grid-match__odds-item grey d-flex">
                    <p>
                      {{ item['ah+3.5_1'] }}
                    </p>
                    <p>
                      3.5
                    </p>
                    <p>
                      {{ item['ah+3.5_2'] }}
                    </p>
                  </div>
                  <div v-else-if="item['ah+4.5_1']" class="grid-match__odds-item grey d-flex">
                    <p>
                      {{ item['ah+4.5_1'] }}
                    </p>
                    <p>
                      4.5
                    </p>
                    <p>
                      {{ item['ah+4.5_2'] }}
                    </p>
                  </div>
                  <div v-else-if="item['ah+0.5_1']" class="grid-match__odds-item grey d-flex">
                    <p>
                      {{ item['ah+0.5_1'] }}
                    </p>
                    <p>
                      0.5
                    </p>
                    <p>
                      {{ item['ah+0.5_2'] }}
                    </p>
                  </div>
                  <div v-else class="grid-match__odds-item grey d-flex">
                    <div class="block2">
                      {{ item['ah0_1'] }}
                    </div>
                    <div class="block2">
                      0
                    </div>
                    <div class="block2">
                      {{ item['ah0_2'] }}
                    </div>
                  </div>

                  <div v-if="item['o+2.5']" class="grid-match__odds-item grey d-flex">
                    <p>
                      O {{ item['o+2.5'] }}
                    </p>
                    <p>
                      2.5
                    </p>
                    <p>
                      U {{ item['u+2.5'] }}
                    </p>
                  </div>
                  <div v-else-if="item['o+0.5']" class="grid-match__odds-item d-flex">
                    <p>
                      O {{ item['o+0.5'] }}
                    </p>
                    <p>
                      0.5
                    </p>
                    <p>
                      U {{ item['u+0.5'] }}
                    </p>
                  </div>
                  <div v-else-if="item['o+1.5']" class="grid-match__odds-item d-flex">
                    <p>
                      O {{ item['o+1.5'] }}
                    </p>
                    <p>
                      1
                    </p>
                    <p>
                      U {{ item['u+1.5'] }}
                    </p>
                  </div>
                  <div v-else-if="item['o+3.5']" class="grid-match__odds-item d-flex">
                    <p>
                      O {{ item['o+3.5'] }}
                    </p>
                    <p>
                      3.5
                    </p>
                    <p>
                      U {{ item['u+3.5'] }}
                    </p>
                  </div>
                  <div v-else-if="item['o+4.5']" class="grid-match__odds-item d-flex">
                    <p>
                      O {{ item['o+4.5'] }}
                    </p>
                    <p>
                      4.5
                    </p>
                    <p>
                      U {{ item['u+4.5'] }}
                    </p>
                  </div>
                  <div v-else-if="item['o+5.5']" class="grid-match__odds-item d-flex">
                    <p>
                      O {{ item['o+5.5'] }}
                    </p>
                    <p>
                      5.5
                    </p>
                    <p>
                      U {{ item['u+5.5'] }}
                    </p>
                  </div>
                  <div v-else class="grid-match__odds-item d-flex">
                    <p>
                      -
                    </p>
                    <p>
                      -
                    </p>
                    <p>
                      -
                    </p>
                  </div>
                </div>
                <a
                    href="https://276863.com"
                    class="odds-comp" target="_blank"
                    rel="nofollow"
                    style="position: relative;z-index: 2;"
                >
                </a>
              </div>
            </div>
            <div
                id="match-popup-4jwq2ghexy3xm0v"
                class="match-popup d-none"
            >
              <div class="statics-popup"></div>
              <div class="incident-popup d-none"></div>
            </div>
          </div>

        </div>
        <div v-if="total">Hiện tại không có sự cạnh tranh</div>

        <button @click="loadMore" class="loadMore">Load More</button>
      </div>

    </div>
  </div>

</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      isHot: false,
      APIkey: 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd',
      socket: null,
      currentPage: 'total',
      events: [],
      web3: {},
      live: [],
      yesterday: [],
      tomorrow: [],
      total: [],
      chunkSize: 20, // 每次加载的数量
      loadedItems: 20 // 已加载的数量
    };
  },
  computed: {
    displayedData() { // 计算属性用于返回要显示在页面上的颜色数组
      if (Array.isArray(this.total)) {
        return this.total.slice(0, this.loadedItems);
        // 进行处理
      } else {

        return this.total;

      }
    }
  },
  mounted() {
    this.fetchFootballTotal();



  },
  methods: {
    goToDetailPage(id) {
      this.$router.push({name: 'detailPage', query: {id: id}});
    },
    loadMore() { // 加载更多按钮点击事件处理函数
      if (this.loadedItems >= this.total.length) return; // 如果已加载的数量等于或超过原始数据数组长度，则停止加载
      this.loadedItems += this.chunkSize; // 增加已加载数量
      if (this.loadedItems > this.total.length) {
        this.loadedItems = this.total.length;
      }   // 如果超过了原始数据数组长度，则将已加载数量设为数组长度，避免超出范围

    },
    async fetchFootballYesterday() {

      try {
        this.isHot = false
        console.log(this.web3, '123')
        this.total = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        // const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        // const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${yesterday}&to=${yesterday}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const total = response.data;
        await Promise.all(total.map(async item => {
          const id = item.match_id;
          const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
          const details = detailsResponse.data[0];
          item.match_date = details.match_date;
          item.match_time = details.match_time;
          item.league_name = details.league_name;
          item.match_hometeam_score = details.match_hometeam_score
          item.match_awayteam_score = details.match_awayteam_score
          item.match_awayteam_halftime_score = details.match_awayteam_halftime_score
          item.match_live = details.match_live
          item.match_status = details.match_status
          item.match_hometeam_halftime_score = details.match_hometeam_halftime_score
          item.match_hometeam_name = details.match_hometeam_name
          item.match_awayteam_name = details.match_awayteam_name
          item.league_logo = details.league_logo
          item.team_home_badge = details.team_home_badge
          item.team_away_badge = details.team_away_badge
          item.match_hometeam_name = details.match_hometeam_name;
          item.match_awayteam_name = details.match_awayteam_name;
        }));
        this.total = total;
        console.log('All data with details:', this.total);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFootballToday() {
      try {
        this.isHot = false
        this.total = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        // const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        // const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${today}&to=${today}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const total = response.data;
        await Promise.all(total.map(async item => {
          const id = item.match_id;
          const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
          const details = detailsResponse.data[0];
          item.match_date = details.match_date;
          item.match_time = details.match_time;
          item.league_name = details.league_name;
          item.match_hometeam_score = details.match_hometeam_score
          item.match_awayteam_score = details.match_awayteam_score
          item.match_awayteam_halftime_score = details.match_awayteam_halftime_score
          item.match_live = details.match_live
          item.match_status = details.match_status
          item.match_hometeam_halftime_score = details.match_hometeam_halftime_score
          item.match_hometeam_name = details.match_hometeam_name
          item.match_awayteam_name = details.match_awayteam_name
          item.league_logo = details.league_logo
          item.team_home_badge = details.team_home_badge
          item.team_away_badge = details.team_away_badge
          item.match_hometeam_name = details.match_hometeam_name;
          item.match_awayteam_name = details.match_awayteam_name;
        }));
        this.total = total;
        console.log('All data with details:', this.total);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFootballTomorrow() {
      try {
        this.isHot = false
        this.total = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        // const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        // const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${tomorrow}&to=${tomorrow}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const total = response.data;
        await Promise.all(total.map(async item => {
          const id = item.match_id;
          const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
          const details = detailsResponse.data[0];
          item.match_date = details.match_date;
          item.match_time = details.match_time;
          item.league_name = details.league_name;
          item.match_hometeam_score = details.match_hometeam_score
          item.match_awayteam_score = details.match_awayteam_score
          item.match_awayteam_halftime_score = details.match_awayteam_halftime_score
          item.match_live = details.match_live
          item.match_status = details.match_status
          item.match_hometeam_halftime_score = details.match_hometeam_halftime_score
          item.match_hometeam_name = details.match_hometeam_name
          item.match_awayteam_name = details.match_awayteam_name
          item.league_logo = details.league_logo
          item.team_home_badge = details.team_home_badge
          item.team_away_badge = details.team_away_badge
          item.match_hometeam_name = details.match_hometeam_name;
          item.match_awayteam_name = details.match_awayteam_name;
        }));
        this.total = total;
        console.log('All data with details:', this.total);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFootballHot() {
      try {
        this.isHot = true;
        this.hot = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        // const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${yesterday}&to=${tomorrow}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const hot = response.data;
        await Promise.all(hot.map(async item => {
          const id = item.match_id;
          const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
          const details = detailsResponse.data[0];
          item.match_date = details.match_date;
          item.match_time = details.match_time;
          item.league_name = details.league_name;
          item.match_hometeam_score = details.match_hometeam_score
          item.match_awayteam_score = details.match_awayteam_score
          item.match_status = details.match_status
          item.match_hometeam_name = details.match_hometeam_name
          item.match_awayteam_name = details.match_awayteam_name
          item.league_logo = details.league_logo
          item.team_home_badge = details.team_home_badge
          item.team_away_badge = details.team_away_badge
          item.match_hometeam_name = details.match_hometeam_name;
          item.match_awayteam_name = details.match_awayteam_name;
        }));
        this.total = hot;
        console.log('All data with details:', this.total);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFootballTotal() {
      try {
        this.isHot = false;
        this.total = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);

        const fetchData = async () => {
          const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${yesterday}&to=${tomorrow}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
          const total = response.data;

          await Promise.all(total.map(async item => {
            const id = item.match_id;
            const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
            const details = detailsResponse.data[0];

            // 将详细数据添加到每个项目中
            item.match_date = details.match_date;
            item.match_time = details.match_time;
            item.league_name = details.league_name;
            item.match_hometeam_score = details.match_hometeam_score;
            item.match_awayteam_score = details.match_awayteam_score;
            item.match_awayteam_halftime_score = details.match_awayteam_halftime_score;
            item.match_live = details.match_live;
            item.match_status = details.match_status;
            item.match_hometeam_halftime_score = details.match_hometeam_halftime_score;
            item.match_hometeam_name = details.match_hometeam_name;
            item.match_awayteam_name = details.match_awayteam_name;
            item.league_logo = details.league_logo;
            item.team_home_badge = details.team_home_badge;
            item.team_away_badge = details.team_away_badge;
          }));

          this.total = total;
          console.log('All data with details:', this.total);
          // 在这里进行处理和渲染详细数据
        };

        await fetchData();

      } catch (error) {
        console.error(error);
        // 请求失败后立即重复请求，直到成功为止
        setTimeout(async () => {
          await this.fetchFootballTotal();
        }, 1000); // 重试间隔为1秒
      }
    },
    async fetchFootballLive() {
      try {
        this.total = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        // const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${yesterday}&to=${tomorrow}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const total = response.data;
        await Promise.all(total.map(async item => {
          const id = item.match_id;
          const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_live=1&match_id=${id}&APIkey=${APIkey}`);
          const details = detailsResponse.data[0];
          item.match_date = details.match_date;
          item.match_time = details.match_time;
          item.league_name = details.league_name;
          item.match_hometeam_score = details.match_hometeam_score
          item.match_awayteam_score = details.match_awayteam_score
          item.match_awayteam_halftime_score = details.match_awayteam_halftime_score
          item.match_live = details.match_live
          item.match_status = details.match_status
          item.match_hometeam_halftime_score = details.match_hometeam_halftime_score
          item.match_hometeam_name = details.match_hometeam_name
          item.match_awayteam_name = details.match_awayteam_name
          item.league_logo = details.league_logo
          item.team_home_badge = details.team_home_badge
          item.team_away_badge = details.team_away_badge
          item.match_hometeam_name = details.match_hometeam_name;
          item.match_awayteam_name = details.match_awayteam_name;
        }));
        this.total = total;
        console.log('All data with details:', this.total);
        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    },
  }
};

</script>

<style lang="scss">
@media (min-width: 700px) {
  .green_title{
    width: 100px;
    height: 30px;
    background: #41a85f;
    border-radius: 50px / 100px;
    margin-top: 10px
  }
}
@media (max-width: 700px) {
  .green_title{
    width: 15vw;
    font-size: 0.75rem;
    height: 30px;
    background: #41a85f;
    border-radius: 50px / 100px;
    margin-top: 10px
  }
}


@import '../styles/common.css';

.container {
  width: 95vw;
  max-width: 95vw;

}

@media (max-width: 760px) {
  .container {
    width: 95%;
  }

}

.loadMore {
  color: white;
  height: 44px;
  width: 100%;
  background-color: #232324;
  justify-content: center; /* 水平居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
}

.grid-match--is-hot {
  background-color: #232324;
}

.body {
  background-color: #333;
}

.list-filter {
  display: flex;

}

@media (max-width: 700px) {
  .list-filter li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    gap: 12px;
    padding: 20px 16px 16px 16px;

    div {
      border-radius: 6px;
      background: #202229;
      color: #fff;
      font-family: Raleway;
      min-width: 120px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 7px;
      font-weight: 700;
      line-height: 18px;
      white-space: nowrap;
      padding: 0 8px;
      border: 1px solid transparent;
    }
  }
}

@media (min-width: 700px) {
  .list-filter li {
    cursor: pointer;
    margin-left: 20px;
    background-color: #232324;
    height: 34px;
    display: flex;
    width: 13%;

  }
}


.list-filter li div {
  justify-content: center; /* 水平居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
  width: 100%; /* 使链接占满整个li元素的宽度 */
}
</style>