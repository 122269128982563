<template>
  <div class="col-xs-12 col-sm-12 col-md-12">
    <div id="view-sticky">

      <div>
        <div class="match-single-top mb-0 mb-lg-3">
          <div
              class="team-live"
              id="match"
              data-fid="23xmvkh8653eqg8"
              data-lid="d23xmvkhdw4qg8n"
              data-homeid="4jwq2gh478ym0ve"
              data-awayid="k82rekho6jlrepz"
              data-status="4"
              data-sport-id="0"
          >
            <div class="team-incident">
              <div class="team-incident-content ">
                <button class="incident-btn incident-btn-target " type="button">
                  <span class="target-home">
                      <span v-for="detail in detail" :key="detail.type"><span
                          v-if="detail.type === 'Shots Total'">{{ detail.home }}</span>
                      </span>
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
                    <path
                        d="M1.52 1.52V10.165C1.52 10.5847 1.17973 10.925 0.76 10.925C0.340271 10.925 0 10.5847 0 10.165V0.95C0 0.425334 0.425334 0 0.95 0H18.05C18.5746 0 19 0.425334 19 0.95V10.165C19 10.5847 18.6597 10.925 18.24 10.925C17.8203 10.925 17.48 10.5847 17.48 10.165V1.52H1.52Z"
                        fill="#5EB24A"/>
                    <path
                        d="M9.49985 10.7917C8.23621 10.7917 7.20815 9.76362 7.20815 8.49998C7.20815 7.23635 8.23621 6.20829 9.49985 6.20829C10.7635 6.20829 11.7915 7.23635 11.7915 8.49998C11.7915 9.76362 10.7635 10.7917 9.49985 10.7917ZM9.49985 6.89579C8.6153 6.89579 7.89565 7.61543 7.89565 8.49998C7.89565 9.38454 8.6153 10.1042 9.49985 10.1042C10.3844 10.1042 11.104 9.38454 11.104 8.49998C11.104 7.61543 10.3844 6.89579 9.49985 6.89579Z"
                        fill="#FFE400"/>
                    <path
                        d="M9.50015 13.0833C6.97288 13.0833 4.91685 11.0273 4.91685 8.50002C4.91685 5.97275 6.97288 3.91671 9.50015 3.91671C12.0274 3.91671 14.0835 5.97275 14.0835 8.50002C14.0835 11.0273 12.0274 13.0833 9.50015 13.0833ZM9.50015 4.60421C7.35196 4.60421 5.60435 6.35183 5.60435 8.50002C5.60435 10.6482 7.35196 12.3958 9.50015 12.3958C11.6483 12.3958 13.396 10.6482 13.396 8.50002C13.396 6.35183 11.6483 4.60421 9.50015 4.60421Z"
                        fill="#FFE400"/>
                    <path
                        d="M9.5 5.29169C9.31025 5.29169 9.15625 5.1377 9.15625 4.94794V3.34375C9.15625 3.154 9.31025 3 9.5 3C9.68975 3 9.84375 3.154 9.84375 3.34375V4.94794C9.84375 5.1377 9.68975 5.29169 9.5 5.29169Z"
                        fill="#FFE400"/>
                    <path
                        d="M9.5 14C9.31025 14 9.15625 13.846 9.15625 13.6562V12.052C9.15625 11.8623 9.31025 11.7083 9.5 11.7083C9.68975 11.7083 9.84375 11.8623 9.84375 12.052V13.6562C9.84375 13.846 9.68975 14 9.5 14Z"
                        fill="#FFE400"/>
                    <path
                        d="M5.94794 8.84375H4.34375C4.154 8.84375 4 8.68975 4 8.5C4 8.31025 4.154 8.15625 4.34375 8.15625H5.94794C6.1377 8.15625 6.29169 8.31025 6.29169 8.5C6.29169 8.68975 6.1377 8.84375 5.94794 8.84375Z"
                        fill="#FFE400"/>
                    <path
                        d="M14.6561 8.84375H13.0519C12.8622 8.84375 12.7082 8.68975 12.7082 8.5C12.7082 8.31025 12.8622 8.15625 13.0519 8.15625H14.6561C14.8458 8.15625 14.9998 8.31025 14.9998 8.5C14.9998 8.68975 14.8458 8.84375 14.6561 8.84375Z"
                        fill="#FFE400"/>
                  </svg>
                  <span class="target-away">
                      <span v-for="detail in detail" :key="detail.type"><span
                          v-if="detail.type === 'Shots Total'">{{ detail.away }}</span></span>

                  </span>
                </button>
                <button class="incident-btn incident-btn-corner  " type="button">
                  <span class="corner-home">
                      <span v-for="detail in detail" :key="detail.type"><span
                          v-if="detail.type === 'Corners'">{{ detail.home }}</span></span>
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M9.74345 9.27441C8.47976 11.1819 6.23298 12.3029 3.95126 12.2119C3.63892 12.2115 3.00548 12.2122 2.69283 12.2119L0.666992 13.9993H9.74345L12.667 9.27441H9.74345Z"
                        fill="#5EB24A"/>
                    <path
                        d="M4.23031 11.2749C5.88703 11.2792 7.49953 10.5282 8.56656 9.27492H5.99969V4.18617L9.88406 2.36273L5.06219 0.0996094V9.5643L3.53125 11.2749H4.23031Z"
                        fill="#FFE400"/>
                  </svg>
                  <span class="corner-away">
                      <span v-for="detail in detail" :key="detail.type"><span
                          v-if="detail.type === 'Corners'">{{ detail.away }}</span></span>
                  </span>
                </button>
                <button class="incident-btn incident-btn-yellow-card  " type="button">
                  <span class="yellow-card-home">
                      <span v-for="detail in detail" :key="detail.type"><span
                          v-if="detail.type === 'Yellow Cards'">{{ detail.home }}</span></span>
                  </span>
                  <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="9" height="12" rx="2" fill="#EFFB37"/>
                  </svg>
                  <span class="yellow-card-away">
                      <span v-for="detail in detail" :key="detail.type"><span
                          v-if="detail.type === 'Yellow Cards'">{{ detail.away }}</span></span>

                  </span>
                </button>
              </div>
            </div>

            <div class="teambox">
              <div class="grid-match__time grid-match__status--normal" style="display:none">
                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.49408 11.0208C5.63601 11.1378 5.80017 11.2057 5.98657 11.2244C6.17297 11.243 6.3378 11.1989 6.48108 11.092C6.54728 11.0295 6.6108 10.9581 6.67162 10.8777C6.73245 10.7973 6.76313 10.7123 6.76367 10.6228C6.764 10.569 6.74792 10.5062 6.71544 10.4344C6.68295 10.3626 6.65034 10.3132 6.61759 10.2862C6.07221 9.75545 5.66979 9.17151 5.41032 8.53439C5.13981 7.90618 5.00653 7.26509 5.01047 6.61111C5.01441 5.95712 5.15543 5.31266 5.43354 4.67769C5.70063 4.05164 6.11003 3.47544 6.66177 2.94909C6.8051 2.8332 6.86629 2.69234 6.84535 2.52652C6.8244 2.36069 6.74289 2.2327 6.6008 2.14254C6.45888 2.02551 6.28638 1.97554 6.08331 1.99264C5.88024 2.00975 5.72349 2.07631 5.61308 2.19233C4.93996 2.83467 4.43654 3.53591 4.10282 4.29607C3.75808 5.06516 3.58335 5.84163 3.57862 6.62551C3.5739 7.4094 3.72836 8.17823 4.04199 8.93204C4.36653 9.69485 4.85056 10.3911 5.49408 11.0208ZM3.02467 0.233384C2.04268 1.15221 1.3015 2.16605 0.801108 3.27495C0.300715 4.38384 0.0470433 5.5161 0.0400831 6.67178C0.0331229 7.82745 0.273145 8.96169 0.760156 10.0746C1.24711 11.1964 1.97604 12.2161 2.94697 13.1338C3.0889 13.2509 3.25306 13.3187 3.43946 13.3374C3.62586 13.356 3.79072 13.3074 3.93405 13.1915C4.04424 13.1114 4.11874 13.0377 4.15755 12.9707C4.19635 12.9037 4.21602 12.8254 4.21656 12.7358C4.21689 12.682 4.20356 12.617 4.17659 12.5408C4.14963 12.4645 4.11439 12.3949 4.07088 12.332C3.1981 11.5133 2.54526 10.6103 2.11235 9.62308C1.67939 8.64482 1.46597 7.6473 1.47209 6.63049C1.47822 5.61368 1.70364 4.61792 2.14837 3.64319C2.59309 2.66845 3.25669 1.7842 4.13919 0.990403C4.28251 0.874513 4.34366 0.740378 4.32264 0.587992C4.30161 0.435606 4.22014 0.300896 4.07822 0.183861C3.90338 0.0666935 3.71995 0.00996445 3.52793 0.0136737C3.33591 0.017382 3.16816 0.0906176 3.02467 0.233384Z"
                      fill="#00FF4B"/>
                  <path
                      d="M12.3712 11.0208C12.2292 11.1378 12.0651 11.2057 11.8787 11.2244C11.6923 11.243 11.5274 11.1989 11.3842 11.092C11.318 11.0295 11.2544 10.9581 11.1936 10.8777C11.1328 10.7973 11.1021 10.7123 11.1016 10.6228C11.1012 10.569 11.1173 10.5062 11.1498 10.4344C11.1823 10.3626 11.2149 10.3132 11.2476 10.2862C11.793 9.75545 12.1954 9.17151 12.4549 8.53439C12.7254 7.90618 12.8587 7.26509 12.8548 6.61111C12.8508 5.95712 12.7098 5.31266 12.4317 4.67769C12.1646 4.05164 11.7552 3.47544 11.2035 2.94909C11.0601 2.8332 10.9989 2.69234 11.0199 2.52652C11.0408 2.36069 11.1223 2.2327 11.2644 2.14254C11.4064 2.02551 11.5789 1.97554 11.7819 1.99264C11.985 2.00975 12.1417 2.07631 12.2522 2.19233C12.9253 2.83467 13.4287 3.53591 13.7624 4.29607C14.1072 5.06516 14.2819 5.84163 14.2866 6.62551C14.2913 7.4094 14.1369 8.17823 13.8232 8.93204C13.4987 9.69485 13.0147 10.3911 12.3712 11.0208ZM14.8406 0.233384C15.8226 1.15221 16.5637 2.16605 17.0641 3.27495C17.5645 4.38384 17.8182 5.5161 17.8252 6.67178C17.8321 7.82745 17.5921 8.96169 17.1051 10.0746C16.6181 11.1964 15.8892 12.2161 14.9183 13.1338C14.7763 13.2509 14.6122 13.3187 14.4258 13.3374C14.2394 13.356 14.0745 13.3074 13.9312 13.1915C13.821 13.1114 13.7465 13.0377 13.7077 12.9707C13.6689 12.9037 13.6492 12.8254 13.6487 12.7358C13.6483 12.682 13.6617 12.617 13.6886 12.5408C13.7156 12.4645 13.7508 12.3949 13.7944 12.332C14.6671 11.5133 15.32 10.6103 15.7529 9.62308C16.1858 8.64482 16.3993 7.6473 16.3931 6.63049C16.387 5.61368 16.1616 4.61792 15.7169 3.64319C15.2721 2.66845 14.6085 1.7842 13.726 0.990403C13.5827 0.874513 13.5216 0.740378 13.5426 0.587992C13.5636 0.435606 13.6451 0.300896 13.787 0.183861C13.9619 0.0666935 14.1453 0.00996445 14.3373 0.0136737C14.5293 0.017382 14.6971 0.0906176 14.8406 0.233384Z"
                      fill="#00FF4B"/>
                  <circle cx="9" cy="7" r="2" fill="#00FF4B"/>
                </svg>
                <text x="54" y="16" class="t_time time t_time_num" fill="#fff" font-size="14" text-anchor="middle"
                      dominant-baseline="middle">Chưa Bắt Đầu
                </text>
              </div>
              <div class="teambox__team teambox__team-home team team--home">
                <img :src=list.team_home_badge alt="Auckland City"
                     class="teambox__team-logo teambox__team-home-logo team__logo" width="100" height="100">
                <div class="teambox__team-name teambox__team-home-name team__name">
                  {{ list.match_hometeam_name }}
                </div>
              </div>
              <div class="teambox__center">
                <div v-if="list.match_hometeam_score" class="teambox__day">{{ list.match_hometeam_score }} &nbsp; ：
                  {{ list.match_awayteam_score }}
                </div>
                <div v-else class="teambox__day">VS</div>

              </div>
              <div class="teambox__team teambox__team-away team team--away">
                <img :src=list.team_away_badge alt="West Coast Rangers"
                     class="teambox__team-logo teambox__team-away-logo team__logo" width="100" height="100">
                <div class="teambox__team-name teambox__team-away-name team__name">
                  {{ list.match_awayteam_name }}
                </div>
              </div>
              <div class="teambox_agg  "></div>
            </div>
            <div class="matchBox-container-odds">
              <div style="display: flex">
                <div v-if="match['ah+1.5_1']" class="item"
                     style="color:#ac4373;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>{{ match['ah+1.5_1'] }}</div>
                    <div>1.5</div>
                    <div>{{ match['ah+1.5_2'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['ah+2.5_1']" class="item"
                     style="color: #ac4373;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>{{ match['ah+2.5_1'] }}</div>
                    <div>2.5</div>
                    <div> {{ match['ah+2.5_2'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['ah+3.5_1']" class="item"
                     style="color: #ac4373;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>{{ match['ah+3.5_1'] }}</div>
                    <div> 3.5</div>
                    <div> {{ match['ah+3.5_2'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['ah+4.5_1']" class="item"
                     style="color: #ac4373;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>{{ match['ah+4.5_1'] }}</div>
                    <div>4.5</div>
                    <div>{{ match['ah+4.5_2'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['ah+0.5_1']" class="item"
                     style="color: #ac4373;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>{{ match['ah+0.5_1'] }}</div>
                    <div>0.5</div>
                    <div>{{ match['ah+0.5_2'] }}</div>
                  </div>

                </div>
                <div v-else class="item"
                     style="color: #FF4C13;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>{{ match['ah0_1'] }}</div>
                    <div>0</div>
                    <div> {{ match['ah0_2'] }}</div>

                  </div>
                </div>
              </div>

              <div style="display: flex">
                <div v-if="match['o+2.5']" class="item"
                     style="color: #7aa4e2;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>O {{ match['o+2.5'] }}</div>
                    <div>2.5</div>
                    <div>U {{ match['u+2.5'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['o+1.5']" class="item"
                     style="color: #FF4C13;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>O {{ match['o+1.5'] }}</div>
                    <div>2.5</div>
                    <div>U {{ match['u+1.5'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['o+3.5']" class="item"
                     style="color: #7aa4e2;;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>O {{ match['o+3.5'] }}</div>
                    <div>3.5</div>
                    <div>U {{ match['u+3.5'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['o+0.5']" class="item"
                     style="color: #7aa4e2;;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>O {{ match['o+0.5'] }}</div>
                    <div>0.5</div>
                    <div>U {{ match['u+0.5'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['o+4.5']" class="item"
                     style="color: #7aa4e2;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>O {{ match['o+4.5'] }}</div>
                    <div>4.5</div>
                    <div>U {{ match['u+4.5'] }}</div>
                  </div>
                </div>
                <div v-else-if="match['o+5.5']" class="item"
                     style="color: #7aa4e2;  display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <div class="block2">
                    <div>O {{ match['o+4.5'] }}</div>
                    <div>2.5</div>
                    <div>U {{ match['u+4.5'] }}</div>
                  </div>
                </div>

              </div>


            </div>
            <!--            <div class="team-vote">-->
            <!--              <div id="carousel-vote" class="carousel slide" data-touch="false" data-interval="false">-->
            <!--                <ol class="carousel-indicators">-->
            <!--                  <li data-target="#carousel-vote" data-slide-to="0" class="active"></li>-->
            <!--                  <li data-target="#carousel-vote" data-slide-to="1"></li>-->
            <!--                </ol>-->
            <!--                <div class="carousel-inner">-->
            <!--                  <div class="carousel-item active">-->
            <!--                    <h5>Theo bạn thì trận này tài hay xỉu?</h5>-->
            <!--                    <div class="vote-item">-->
            <!--                      <button id="vote-btn-1" class="vote-btn vote-btn-1" type="button" disabled data-type="1"-->
            <!--                              data-value="1">-->
            <!--                        <span>Tài</span>-->
            <!--                        <svg>-->
            <!--                          <use xlink:href="#vote-bg-1"></use>-->
            <!--                        </svg>-->
            <!--                      </button>-->

            <!--                      <button id="vote-btn-3" class="vote-btn vote-btn-1" type="button" disabled data-type="1"-->
            <!--                              data-value="3">-->
            <!--                        <span>Xỉu</span>-->
            <!--                        <svg>-->
            <!--                          <use xlink:href="#vote-bg-1"></use>-->
            <!--                        </svg>-->
            <!--                      </button>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="carousel-item">-->
            <!--                    <h5>Theo bạn thì trận này đội nào sẽ thắng?</h5>-->
            <!--                    <div class="vote-item">-->
            <!--                      <button id="vote-btn-4" class="vote-btn vote-btn-2" type="button" disabled data-type="2"-->
            <!--                              data-value="4">-->
            <!--                        <span>Chủ nhà</span>-->
            <!--                        <svg>-->
            <!--                          <use xlink:href="#vote-bg-1"></use>-->
            <!--                        </svg>-->
            <!--                      </button>-->
            <!--                      <button id="vote-btn-5" class="vote-btn vote-btn-2" type="button" disabled data-type="2"-->
            <!--                              data-value="5">-->
            <!--                        <span>Hòa</span>-->
            <!--                        <svg>-->
            <!--                          <use xlink:href="#vote-bg-2"></use>-->
            <!--                        </svg>-->
            <!--                      </button>-->
            <!--                      <button id="vote-btn-6" class="vote-btn vote-btn-2" type="button" disabled data-type="2"-->
            <!--                              data-value="6">-->
            <!--                        <span>Đội Khách</span>-->
            <!--                        <svg>-->
            <!--                          <use xlink:href="#vote-bg-1"></use>-->
            <!--                        </svg>-->
            <!--                      </button>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <button class="carousel-control-prev" type="button" data-target="#carousel-vote" data-slide="prev">-->
            <!--                  <i class="arrow left"></i>-->
            <!--                </button>-->
            <!--                <button class="carousel-control-next" type="button" data-target="#carousel-vote" data-slide="next">-->
            <!--                  <i class="arrow right"></i>-->
            <!--                </button>-->
            <!--              </div>-->

            <!--              <svg aria-hidden="true" style="position: absolute; width: 0px; height: 0px; overflow: hidden;">-->
            <!--                <symbol id="vote-bg-1" fill="none">-->
            <!--                  <rect x="0.5" y="0.5" width="97" height="27" rx="3.5" fill="#292B2E"-->
            <!--                        stroke="url(#paint0_linear_408_2206)"/>-->
            <!--                  <defs>-->
            <!--                    <linearGradient id="paint0_linear_408_2206" x1="43" y1="9.98856e-07" x2="43" y2="28"-->
            <!--                                    gradientUnits="userSpaceOnUse">-->
            <!--                      <stop stop-color="#1CAA17"/>-->
            <!--                      <stop offset="1" stop-color="#005A19"/>-->
            <!--                    </linearGradient>-->
            <!--                  </defs>-->
            <!--                </symbol>-->
            <!--                <symbol id="vote-bg-2">-->
            <!--                  <rect x="0.5" y="0.5" width="97" height="27" rx="3.5" fill="#292B2E" stroke="#5C5C5C"/>-->
            <!--                </symbol>-->
            <!--                <symbol id="vote-bg-active">-->
            <!--                  <rect x="0.5" y="0.5" width="97" height="27" rx="3.5" fill="#204276"-->
            <!--                        stroke="url(#paint0_linear_408_2246)"/>-->
            <!--                  <defs>-->
            <!--                    <linearGradient id="paint0_linear_408_2246" x1="43" y1="9.98856e-07" x2="43" y2="28"-->
            <!--                                    gradientUnits="userSpaceOnUse">-->
            <!--                      <stop stop-color="#0B63A3"/>-->
            <!--                      <stop offset="1" stop-color="#1553EF"/>-->
            <!--                    </linearGradient>-->
            <!--                  </defs>-->
            <!--                </symbol>-->
            <!--              </svg>-->
            <!--            </div>-->
          </div>
          <!--          <div class="teambox__odds flex-row d-flex mb-2">-->
          <!--            <div class="company d-flex d-lg-none justify-content-between">-->
          <!--              <div class="d-flex flex-row-reverse gap-1 align-items-center company-choose">-->
          <!--                <div class="oddContent">-->
          <!--              <span><img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/23.png" alt="#"-->
          <!--                         class="oddType img-fluid"> <svg width="20" height="20" viewBox="0 0 20 20" fill="none"-->
          <!--                                                         xmlns="http://www.w3.org/2000/svg">-->
          <!--                  <path-->
          <!--                      d="M2.93423 8.12477H17.0676C17.5509 8.12477 17.9592 7.81644 18.0842 7.34977C18.2092 6.89144 18.0176 6.40811 17.6092 6.16644L10.7676 2.08311C10.2926 1.79977 9.70923 1.79977 9.24256 2.08311L2.39256 6.16644C1.9759 6.40811 1.79256 6.89144 1.91756 7.34977C2.04256 7.81644 2.4509 8.12477 2.93423 8.12477ZM9.8759 3.15811C9.9509 3.11644 10.0509 3.11644 10.1259 3.15811L16.3592 6.87477H3.64256L9.8759 3.15811Z"-->
          <!--                      fill="#4FA747"/>-->
          <!--                  <path-->
          <!--                      d="M16.6667 13.5417H16.4583V10C16.4583 9.65833 16.175 9.375 15.8333 9.375C15.4917 9.375 15.2083 9.65833 15.2083 10V13.5417H13.125V10C13.125 9.65833 12.8417 9.375 12.5 9.375C12.1583 9.375 11.875 9.65833 11.875 10V13.5417H8.125V10C8.125 9.65833 7.84167 9.375 7.5 9.375C7.15833 9.375 6.875 9.65833 6.875 10V13.5417H4.79167V10C4.79167 9.65833 4.50833 9.375 4.16667 9.375C3.825 9.375 3.54167 9.65833 3.54167 10V13.5417H3.33333C2.53333 13.5417 1.875 14.2 1.875 15V16.6667C1.875 17.0083 2.15833 17.2917 2.5 17.2917H17.5C17.8417 17.2917 18.125 17.0083 18.125 16.6667V15C18.125 14.2 17.4667 13.5417 16.6667 13.5417ZM16.875 16.0417H3.125V15C3.125 14.8833 3.21667 14.7917 3.33333 14.7917H16.6667C16.7833 14.7917 16.875 14.8833 16.875 15V16.0417Z"-->
          <!--                      fill="#4FA747"/>-->
          <!--              </svg></span>-->

          <!--                  <ul class="  oddList">-->
          <!--                    <li class="flex justify-items-center align-items-center">-->
          <!--                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/8.png" data-id="8"-->
          <!--                           class="img-fluid" alt="">-->
          <!--                    </li>-->
          <!--                    <li class="flex justify-items-center align-items-center">-->
          <!--                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/31.png" data-id="31"-->
          <!--                           class="img-fluid" alt="">-->
          <!--                    </li>-->
          <!--                    <li class="flex justify-items-center align-items-center">-->
          <!--                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/3.png" data-id="3"-->
          <!--                           class="img-fluid" alt="">-->
          <!--                    </li>-->
          <!--                    <li class="flex justify-items-center align-items-center">-->
          <!--                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/23.png" data-id="23"-->
          <!--                           class="img-fluid" alt="">-->
          <!--                    </li>-->
          <!--                  </ul>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="d-flex teambox__odds&#45;&#45;type gap-2">-->
          <!--                <span data-type="1">Châu Á</span>-->
          <!--                <span data-type="2">Châu Âu</span>-->
          <!--                <span data-type="3">Tài xỉu</span>-->
          <!--                <span data-type="4">Phạt góc</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            &lt;!&ndash;            <div class="table flex-1 company p-0   d-lg-flex">&ndash;&gt;-->
          <!--            &lt;!&ndash;              <div class="head d-flex">&ndash;&gt;-->
          <!--            &lt;!&ndash;                <div class="oddContent">&ndash;&gt;-->
          <!--            &lt;!&ndash;              <span>Chọn nhà cái&ndash;&gt;-->
          <!--            &lt;!&ndash;                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6"&ndash;&gt;-->
          <!--            &lt;!&ndash;                       fill="none">&ndash;&gt;-->
          <!--            &lt;!&ndash;                      <path d="M7.525 0.5L8 1.04688L4 5.5L0 1.04688L0.475 0.5L4 4.40625L7.525 0.5Z" fill="#4FA747" />&ndash;&gt;-->
          <!--            &lt;!&ndash;                  </svg>&ndash;&gt;-->
          <!--            &lt;!&ndash;              </span>&ndash;&gt;-->
          <!--            &lt;!&ndash;                  <ul class="  oddList">&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <li class="flex justify-items-center align-items-center">&ndash;&gt;-->
          <!--            &lt;!&ndash;                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/8.png" data-id="8" class="img-fluid" alt="">&ndash;&gt;-->
          <!--            &lt;!&ndash;                    </li>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <li class="flex justify-items-center align-items-center">&ndash;&gt;-->
          <!--            &lt;!&ndash;                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/31.png" data-id="31" class="img-fluid" alt="">&ndash;&gt;-->
          <!--            &lt;!&ndash;                    </li>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <li class="flex justify-items-center align-items-center">&ndash;&gt;-->
          <!--            &lt;!&ndash;                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/3.png" data-id="3" class="img-fluid" alt="">&ndash;&gt;-->
          <!--            &lt;!&ndash;                    </li>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <li class="flex justify-items-center align-items-center">&ndash;&gt;-->
          <!--            &lt;!&ndash;                      <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/23.png" data-id="23" class="img-fluid" alt="">&ndash;&gt;-->
          <!--            &lt;!&ndash;                    </li>&ndash;&gt;-->
          <!--            &lt;!&ndash;                  </ul>&ndash;&gt;-->
          <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
          <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <div class="row box flex-1 align-items-center">&ndash;&gt;-->
          <!--            &lt;!&ndash;                <img src="https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/company/23.png" alt="#" class="oddType img-fluid">&ndash;&gt;-->
          <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
          <!--            <div class="table flex-1 state">-->
          <!--              <div class="head"></div>-->
          <!--              <div class="row box d-flex">-->
          <!--                <div class="d-flex w-100 justify-content-center align-items-center"><span>FT</span></div>-->
          <!--              </div>-->
          <!--              <div class="row box d-flex">-->
          <!--                <div class="d-flex w-100 justify-content-center align-items-center"><span>HT</span></div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="row m-0 flex-1 showOdds" style="display: flex">-->
          <!--              <div class="table flex-1 head-control d-flex d-lg-none align-items-center justify-content-center h-on"-->
          <!--                   data-type="1">-->
          <!--                <span>Châu Á</span>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 state d-block d-lg-none h-on" data-type="1">-->
          <!--                <div class="head"></div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>FT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>HT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 eu h-on" data-type="1">-->
          <!--                <div class="head">-->
          <!--                  <span>Châu Á</span>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 head-control d-flex d-lg-none align-items-center justify-content-center h-on"-->
          <!--                   data-type="2">-->
          <!--                <span>Châu Âu</span>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 state d-block d-lg-none h-on" data-type="2">-->
          <!--                <div class="head"></div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>FT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>HT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 eu h-on" data-type="2">-->
          <!--                <div class="head">-->
          <!--                  <span>Châu Âu</span>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 head-control d-flex d-lg-none align-items-center justify-content-center h-on"-->
          <!--                   data-type="3">-->
          <!--                <span>Tài xỉu</span>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 state d-block d-lg-none h-on" data-type="3">-->
          <!--                <div class="head"></div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>FT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>HT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 eu h-on" data-type="3">-->
          <!--                <div class="head">-->
          <!--                  <span>Tài xỉu</span>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 head-control d-flex d-lg-none align-items-center justify-content-center h-on"-->
          <!--                   data-type="4">-->
          <!--                <span>Phạt góc</span>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 state d-block h-on" data-type="4">-->
          <!--                <div class="head"></div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>FT</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100 justify-content-center align-items-center">-->
          <!--                    <span>Sớm</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="table flex-1 eu h-on" data-type="4">-->
          <!--                <div class="head">-->
          <!--                  <span>Phạt góc</span>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row box d-flex">-->
          <!--                  <div class="d-flex w-100">-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                    <div class="col cur-pointer">-->
          <!--                      <span class="">-</span>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->


          <div class="marquee-container mb-2" style="display:none">
            <div class="marquee-box">

            </div>
          </div>

        </div>


        <div class="match-option">

          <div class="match-option-items">
            <div class="match-option-items1" :class="{'green': pageIndex === 'playsInfo' }" @click="changePage('playsInfo')">Thông tin người chơi
            </div>
            <div :class="{'green': pageIndex === 'matchDetail' }" @click="changePage('matchDetail')">Số liệu thống kê
            </div>
            <div :class="{'green': pageIndex === 'video' }" @click="changePage('video')">Điểm nổi bật</div>
            <div :class="{'green': pageIndex === 'goalscorer' }" @click="changePage('goalscorer')">Ghi bàn</div>
            <div :class="{'green': pageIndex === 'card' }" @click="changePage('card')">Thẻ phạt</div>
            <div :class="{'green': pageIndex === 'H2H' }" @click="changePage('H2H')">H2H</div>


          </div>

        </div>

        <div class="table-detail">
          <div v-if="currentPage==='goalscorer'" class="goalscorer">

            <div class="detail" style="  background-color: #212428;">
              <div>Thời gian</div>
              <div>Ghi bàn</div>
              <div>Hỗ trợ</div>
              <div>Đội</div>

            </div>
            <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in list.goalscorer" :key="index" class="detail">
              <div>{{ item.time }}</div>

              <div v-if=" item.home_scorer">{{ item.home_scorer }}</div>
              <div v-else>{{ item.away_scorer }}</div>

              <div v-if=" item.home_assist">{{ item.home_assist }}</div>
              <div v-else>{{ item.away_assist }}</div>

              <div v-if=" item.home_scorer">đội chủ nhà</div>
              <div v-else>đội</div>
            </div>


          </div>
          <div v-else-if="currentPage==='card'" class="goalscorer">

            <div class="detail" style="  background-color: #212428;">
              <div>Thời gian</div>
              <div>Người chơi</div>
              <div>Loại thẻ</div>
              <div>Đội</div>

            </div>
            <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in list.cards" :key="index" class="detail">
              <div>{{ item.time }}</div>

              <div v-if=" item.home_fault">{{ item.home_fault }}</div>
              <div v-else>{{ item.away_fault }}</div>

              <div v-if=" item.card==='yellow card'">thẻ vàng</div>
              <div v-else>thẻ đỏ</div>

              <div v-if=" item.home_fault">đội chủ nhà</div>
              <div v-else>đội</div>
            </div>


          </div>
          <div v-else-if="currentPage==='H2H'" class="H2H">

            <div>
              <div class="H2H-title">
                <div> Lịch sử đối đầu</div></div>
            </div>
            <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in H2H.firstTeam_VS_secondTeam" :key="item.match_id" class="H2H-detail">
             <div class="H2H-time">{{item.match_date}}</div>
              <div class="H2H-league">
                <div>
                  <img :src="item.league_logo" >
                </div>
                <div style="width: 5%"></div>
                <div>{{ item.league_name }}</div>
              </div>
              <div class="H2H-home" >
                <div class="H2H-home-img"><img :src="item.team_home_badge" ></div>

                <div class="H2H-home-name">{{ item.match_hometeam_name }}</div>
              </div>
              <div style="width: 5%">VS&nbsp;</div>
              <div class="H2H-away">
                <div class="H2H-away-name">{{ item.match_awayteam_name }}</div>
                <div class="H2H-away-img">
                  <img :src="item.team_away_badge" >
                </div>
              </div>
              <div class="H2H-score">
                <div>{{ item.match_hometeam_score }}</div>
                &nbsp; <div>:</div>&nbsp;
                <div>{{ item.match_awayteam_score }}</div>

              </div>

            </div>

            <div style="height: 20px"></div>

            <div>
              <div class="H2H-title">
                <div>Kết quả đội chủ nhà</div></div>
            </div>
            <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in H2H.firstTeam_lastResults" :key="item.match_id" class="H2H-detail">
              <div class="H2H-time">{{item.match_date}}</div>
              <div class="H2H-league">
                <div>
                  <img :src="item.league_logo" >
                </div>
                <div style="width: 5%"></div>
                <div>{{ item.league_name }}</div>
              </div>
              <div class="H2H-home" >
                <div class="H2H-home-img"><img :src="item.team_home_badge" ></div>

                <div class="H2H-home-name">{{ item.match_hometeam_name }}</div>
              </div>
              <div style="width: 5%">VS&nbsp;</div>
              <div class="H2H-away">
                <div class="H2H-away-name">{{ item.match_awayteam_name }}</div>
                <div class="H2H-away-img">
                  <img :src="item.team_away_badge" >
                </div>
              </div>
              <div class="H2H-score">
                <div>{{ item.match_hometeam_score }}</div>
                &nbsp; <div>:</div>&nbsp;
                <div>{{ item.match_awayteam_score }}</div>

              </div>

            </div>

            <div style="height: 20px"></div>


            <div>
              <div class="H2H-title">
                <div>Kết quả đội khách
                </div></div>
            </div>
            <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in H2H.secondTeam_lastResults" :key="item.match_id" class="H2H-detail">
              <div class="H2H-time">{{item.match_date}}</div>

              <div class="H2H-league">
                <div>
                  <img :src="item.league_logo" >
                </div>
                <div style="width: 10%"></div>
                <div>{{ item.league_name }}</div>
              </div>
              <div class="H2H-home" >
                <div class="H2H-home-img"><img :src="item.team_home_badge" ></div>

                <div class="H2H-home-name">{{ item.match_hometeam_name }}</div>
              </div>
              <div style="width: 10%">VS&nbsp; </div>
              <div class="H2H-away">
                <div class="H2H-away-name">{{ item.match_awayteam_name }}</div>
                <div class="H2H-away-img">
                  <img :src="item.team_away_badge" >
                </div>
              </div>
              <div class="H2H-score">
                <div>{{ item.match_hometeam_score }}</div>
                &nbsp; <div>:</div>&nbsp;
                <div>{{ item.match_awayteam_score }}</div>

              </div>

            </div>

          </div>
          <div v-else-if="currentPage==='matchDetail'" class="match-detail">
            <div class="detail" style="  background-color: #212428;">
              <div>ĐỘI NHÀ</div>
              <div>THÔNG SỐ</div>
              <div>ĐỘI KHÁCH</div>
            </div>
            <div :style="{ backgroundColor: getRowColor(index) }"  v-for="(item,index) in list.statistics" :key="index" class="detail">
              <div>{{ item.home }}</div>
              <div>{{ item.type }}</div>
              <div>{{ item.away }}</div>
            </div>


          </div>
          <div v-else-if="currentPage==='video'" class="video">
            <video ref="videoPlayer" controls :src="videoSrc"></video>
            <!--          <button @click="playVideo">播放</button>-->
            <!--          <button @click="pauseVideo">暂停</button>-->
          </div>
          <div v-else-if="currentPage==='playsInfo'" class="playsInfo">
            <div class="playsInfo-title" >
              <div class="playsInfo-content" >
                <div  style="display: flex;justify-content: center;align-items: center;  background-color: #212428;">
                <div style="margin-left: 10%">Đội hình chủ nhà</div>
                </div>
                <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in list.lineup.home.starting_lineups" :key="index">{{ item.lineup_player }}</div>

              </div>

              <div class="playsInfo-content">
                <div style="display: flex;justify-content: center;align-items: center;  background-color: #212428;">
                 <div> Đội hình đội khách</div>
                </div>
                <div :style="{ backgroundColor: getRowColor(index) }" v-for="(item,index) in list.lineup.away.starting_lineups" :key="index">{{ item.lineup_player }}</div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
    <!--    <iframe src="../widget_34214/index.html" width="100%" height="1000"></iframe>-->
  </div>

</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      pageIndex: 'H2H',
      currentPage: 'H2H',
      videoSrc: 'path_to_your_video.mp4',
      list: [],
      detail: [],
      homePlaysInfo: [],
      awayPlaysInfo: [],
      match: {},
      H2H: {}
    };
  },

  computed: {},
  mounted() {

    this.fetchFootballTotal();
    this.fetchFootballOdd();
    this.fetchFootballLeagues()
    this.fetchFootballVideo()
    this.fetchFootballDetail()
  },
  methods: {
    getRowColor(index) {
      return index % 2 === 0 ? '#4d4d4d' : '#333333';
    },
    changePage(item) {
      this.currentPage = item
      this.pageIndex = item
    },
    fetchFootballDetail() {
      const id = this.$route.query.id;
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';

      axios.get(`https://apiv3.apifootball.com/?action=get_statistics&match_id=${id}&APIkey=${APIkey}`)
          .then(response => {
            this.detail = response.data[id].statistics;
            console.log(this.detail, '666')

          })
          .catch(error => {
            console.error(error);
          });
    },
    fetchFootballVideo() {
      const id = this.$route.query.id;
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';

      axios.get(`https://apiv3.apifootball.com/?action=get_videos&match_id=${id}&APIkey=${APIkey}`)
          .then(response => {
            this.videoSrc = response.data[0].video_url;
            console.log(response, 'alldata')

          })
          .catch(error => {
            console.error(error);
          });
    },

    async fetchFootballTotal() {
      try {
        const id = this.$route.query.id;
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';

        const eventsURL = `https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`;
        const H2HURL = `https://apiv3.apifootball.com/?action=get_H2H&APIkey=${APIkey}`;

        const response = await axios.get(eventsURL);
        this.list = response.data[0];
        console.log(this.list, 'alldata');

        const countryMap = {
          "Throw In": "Ném biên",
          "Free Kick": "Đá phạt",
          "Goal Kick": "Đá phạt góc",
          "Penalty": "Phạt đền",
          "Substitution": "Thay người",
          "Attacks": "Tấn công",
          "Dangerous Attacks": "Tấn công nguy hiểm",
          "On Target": "Trúng đích",
          "Off Target": "Không trúng đích",
          "Shots Total:": "Tổng số cú sút",
          "Shots On Goal": "Sút trúng khung thành",
          "Shots Off Goal": "Sút không trúng khung thành",
          "Shots Blocked": "Cú sút bị chặn",
          "Shots Inside Box": "Sút trong vòng cấm",
          "Shots Outside Box": "Sút ngoài vòng cấm",
          "Fouls": "Phạm lỗi",
          "Corners": "Phạt góc",
          "Ball Possession": "Kiểm soát bóng",
          "Yellow Cards": "Thẻ vàng",
          "Saves": "Cứu thua",
          "Passes Total": "Tổng số đường chuyền",
          "Passes Accurate": "Đường chuyền chính xác",
          "Belgium": "BỈ",
          "Switzerland": "THỤY SĨ",
          "Egypt": "AI CẬP",
          "FA Cup": "FA CUP",
          "Thailand": "THÁI",
          "Indonesia": "INDO",
          "Bundesliga": "BUNDESLIGA 2",

          // ... 其他国家的映射关系
        };
        const mappedCountries = response.data[0].statistics.map(country => {
          return {
            away: country.away,
            home: country.home,
            type: countryMap[country.type] || "", // 使用映射关系添加新字段
            // 其他字段
          };
        });
        this.list.statistics=mappedCountries


        const H2HResponse = await axios.get(`${H2HURL}&firstTeamId=${this.list.match_hometeam_id}&secondTeamId=${this.list.match_awayteam_id}`);
        this.H2H = H2HResponse.data;
        console.log(this.H2H, 'H2H data');
      } catch (error) {
        console.error(error);
      }
    },
    fetchFootballOdd() {
      const id = this.$route.query.id;

      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      // const today = new Date().toISOString().slice(0, 10);
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
      const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
      axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${yesterday}&to=${tomorrow}&odd_bookmakers=Marathon&match_id=${id}&APIkey=${APIkey}`)
          .then(response => {
            console.log(response)
            this.match = response.data[0]
          })
          .catch(error => {
            console.error(error);
          });
      // const id ='322020'
      // // const id = this.$route.query.id;
      // const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      //
      // axios.get(`https://apiv3.apifootball.com/?apiv3.apifootball.com/?action=get_odds&match_id=${id}&APIkey=${APIkey}`)
      //     .then(response => {
      //       this.odd = response.data;
      //       console.log(response)
      //
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
    },
    fetchFootballLeagues() {

      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      // const today = new Date().toISOString().slice(0, 10);
      // const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
      // const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
      axios.get(`https://apiv3.apifootball.com/?action=get_leagues&APIkey=${APIkey}`)
          .then(response => {
            console.log(response, '1234')

          })
          .catch(error => {
            console.error(error);
          });
      // const id ='322020'
      // // const id = this.$route.query.id;
      // const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      //
      // axios.get(`https://apiv3.apifootball.com/?apiv3.apifootball.com/?action=get_odds&match_id=${id}&APIkey=${APIkey}`)
      //     .then(response => {
      //       this.odd = response.data;
      //       console.log(response)
      //
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
    }
  }
};
</script>

<style scoped lang="scss">
@media (max-width: 700px) {
  .table-detail{
    font-size: 10px;
    color: white;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .H2H {
    font-size: 9px;
    width: 100%;
    //margin-left: 7%;
    color: white;
    //padding-left: 10%;
    img {
      height: 17px;
      width: 17px;
      display: flex;
      justify-content: center;
    }
    .H2H-title{
      display: flex;
      align-items: center;
      div{
        margin-left: 5%;

      }
      background-color: #212428;
      //height: 50px;
    }
    .H2H-detail {
      border: 1px solid #4d4d4d;
      //height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .H2H-time{
        //margin-left: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24%;
      }
      .H2H-league{
        //margin-left: 10%;
        display: none;
        justify-content: flex-start;
        width: 0%;
        div{
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .H2H-home{
        display: flex;
        justify-content: center;
        width: 33%;
        .H2H-home-name{
          width: 80%;
          display: flex;
          justify-content: flex-start;
        }
        .H2H-home-img{
          width: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
      .H2H-away{
        display: flex;
        justify-content: center;
        width: 33%;
        .H2H-away-name{
          width: 80%;
          display: flex;
          justify-content: flex-end;
        }
        .H2H-away-img{
          width: 20%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

        }
      }
      .H2H-score{
        display: flex;
        justify-content: center;
        width: 10%;
      }
    }
  }
  .playsInfo {
    width: 100%;
    .playsInfo-title {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #4d4d4d;

      .playsInfo-content {
        width: 100%;
font-size: 13px;
        display: flex;
        flex-direction: column;

        div {
          display: flex;
          height: 50px;
          //font-size: 16px;

          justify-content: center;
          align-items: center;


        }
      }


    }
  }
  .goalscorer {
    font-size: 16px;
    width: 100%;

    .detail {
      border: 1px solid #4d4d4d;

      display: flex;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 50px;
      }
    }
  }
  .match-detail {
    font-size: 16px;
    width: 100%;
    .detail {
      border: 1px solid #4d4d4d;

      display: flex;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
        height: 50px;
      }
    }
  }
}

@media (min-width: 700px) {
  .table-detail{
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    width: 95%;
  }
  .H2H {
    font-size: 16px;
width: 100%;
margin-left: 7%;
    color: white;
    //padding-left: 10%;
    img {
      height: 30px;
      width: 30px;

    }
.H2H-title{
  display: flex;
  align-items: center;
  div{
    margin-left: 10%;

  }
  background-color: #212428;
  height: 50px;
}
    .H2H-detail {
      border: 1px solid #4d4d4d;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .H2H-time{
        margin-left: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
      }
.H2H-league{
  margin-left: 2%;

  //margin-left: 10%;
  display: flex;
  justify-content: flex-start;
  width: 30%;
}

      .H2H-home{
        display: flex;
        justify-content: center;
        width: 20%;
        .H2H-home-name{
          width: 80%;
          display: flex;
          justify-content: flex-start;
        }
        .H2H-home-img{
         width: 20%;
          display: flex;
          justify-content: flex-start;
        }
      }
      .H2H-away{
        display: flex;
        justify-content: center;
        width: 20%;
        .H2H-away-name{
          width: 80%;
          display: flex;
          justify-content: flex-end;
        }
        .H2H-away-img{
          width: 20%;
          display: flex;
          justify-content: flex-end;
        }
      }
      .H2H-score{
        display: flex;
        justify-content: center;
        width: 25%;
      }
    }
  }
  .playsInfo {
    width: 80%;
    .playsInfo-title {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #4d4d4d;

      .playsInfo-content {
        width: 50%;

        display: flex;
        flex-direction: column;

        div {
          display: flex;
          height: 50px;
          font-size: 16px;

          justify-content: center;
          align-items: center;


        }
      }


    }
  }
  .goalscorer {
    font-size: 16px;
    width: 80%;

    .detail {
      border: 1px solid #4d4d4d;

      display: flex;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 50px;
      }
    }
  }
  .match-detail {
    font-size: 16px;
    width: 80%;
    .detail {
      border: 1px solid #4d4d4d;

      display: flex;
      justify-content: center;
      align-items: center;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
        height: 50px;
      }
    }
  }
}

.matchBox-container-odds {
  //display: flex;
  .item {
    width: 100%;
    display: flex;

    .block2 {
      width: 100%;
      display: flex;

      div {
        font-size: 17px;
        height: 40px;
        width: 30%;
        //margin-left: %;
      }
    }
  }
}



@media (min-width: 700px) {
  .match-option {
    height: 60px;
    margin-top: 30px;
    margin-bottom: 30px;

    background-color: #212428;
    display: flex;
    justify-content: center;
    align-items: center;
.match-option-items{
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    cursor: pointer;
    //width: 15%;
    margin-left: 10%;
    font-size: 18px;
    //margin-left: 10px;
    white-space: nowrap; /* 不换行 */

  }
}


  }
}

@media (max-width: 700px) {

  .match-option {
    height: 60px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
    background-color: #212428;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* 设置固定宽度 */
    //width: 2000px;
    //max-width: 600px;
.match-option-items{
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  .match-option-items1{
    margin-left: 300px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    //width: 200px;
    cursor: pointer;
    //margin-left: 20%;
     white-space: nowrap; /* 不换行 */
    font-size: 14px;
    margin-left: 30px;

  }
}

  }
}

.marquee-container {
  position: relative;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #000;
  text-align: left;
  background: #ffc107;
  border-radius: 4px;
  overflow: hidden
}

.marquee-container:hover .marquee-box {
  animation-play-state: paused
}

.marquee-container .marquee-box {
  position: absolute;
  display: flex;
  gap: 60px;
  white-space: nowrap;
  animation: marquee 15s linear infinite
}

.marquee-container div, .marquee-container p {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  font-size: 17px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.marquee-container div:last-child, .marquee-container p:last-child {
  margin-right: 0
}

.marquee-container a {
  color: #dc3545;
  text-decoration: underline;
  font-weight: bold;
}

.marquee-container img {
  width: 20px;
  height: 20px;
  vertical-align: sub
}

@keyframes marquee {
  0% {
    left: 100%
  }

  100% {
    left: 0;
    transform: translatex(-100%)
  }
}

.odds-header {
  background: #292B2E;
  padding: 0.38rem 0.62rem;
}

.teambox__odds--mb .oddContent span {
  color: #4FA747;
  font-family: Oswald;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  text-transform: uppercase;
}

.teambox__odds--mb .odds-type {
  display: flex;
  gap: 0.5rem;
}

.teambox__odds--mb .odds-type span {
  color: #FFF;
  font-family: 'Oswald';
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  /* 163.636% */
  text-transform: uppercase;
  padding: 0.25rem 0.38rem;
  background: #292B2E;
  border: 1px solid rgba(28, 30, 31, 1);
  border-radius: 2px;
}

.teambox__odds--mb .odds-type span.active {
  border-color: #009139;
  color: #009139;
  box-shadow: 0px 2px 12px rgba(79, 167, 71, 0.20);
}

.teambox__odds {
  background: #212324;
  border-radius: 6px;
  border: 1px solid #3D434D;
}

.oddContent {
  position: relative;
}

.oddContent > span {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.oddContent .oddList {
  background-color: #fff;
  position: absolute;
  z-index: 10;
  top: 35px;
  left: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.oddContent .oddList li {
  width: 115px;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 10px;
}

.oddType {
  max-width: 115px;
}

.teambox__odds .table {
  border-right: 1px solid #3D434D;
}

.teambox__odds .table:last-child {
  border: 0px;
}

.teambox__odds .company {
  flex-direction: column;
  padding: 10px 20px;
  flex: none;
  width: 160px;
}

.teambox__odds .company .head {
  padding: 0px 14px;
}

.teambox__odds .company select {
  background: transparent;
  border: 0px;
  outline: none;
  color: #FFF;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.teambox__odds .company .pointer {
  margin-bottom: 15px;
  width: 100%;
}

.teambox__odds .company .pointer:last-child {
  margin-bottom: 0;
}

.teambox__odds .state {
  width: 56px;
  flex: none;
}

.teambox__odds .state span {
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}

.teambox__odds .head {
  background: #292B2E;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 42px;
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 153.846% */
  text-transform: uppercase;
  width: 100%;
  border-bottom: 1px solid #3D434D;
}

.teambox__odds .box {
  min-height: 42px;
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #3D434D;
  margin: 0px;
}

.teambox__odds .type .box {
  height: 84px;
}

.teambox__odds .box:last-child {
  border-bottom: 0px;
}

.teambox__odds .box .col {
  align-items: center;
  text-align: center;
  flex: 1;
  justify-content: center;
  display: flex;
  font-family: 'Oswald';
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
}

@media screen and (max-width: 991px) {
  .oddType {
    max-width: 60px;
  }
  /* new */
  /* .showOdds .table {border-top: 1px solid #3D434D;border-bottom: 0px;} */
  .head.active::before {
    display: none;
  }

  .showOdds {
    grid-gap: 1px;
    background: #3D434D;
    border-top: 1px solid #3D434D;
  }

  .showOdds .table {
    border-bottom: 0;
    border-right: 0px;
  }

  .showOdds .h-on {
    height: 0;
    opacity: 0;
    overflow: hidden;
    margin-bottom: 0px !important;
  }

  .showOdds .h-off {
    height: auto;
    opacity: 1;
  }

  .teambox__odds .showOdds > * {
    background-color: #292B2E;
    /* màu nền của các phần tử con */
  }

  .showOdds .head-control span {
    background: none;
    color: #FFF;
    font-family: 'Raleway';
    font-size: 0.6125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    /* 138.462% */
  }

  /* .teambox__odds .showOdds {border-top: 1px solid #3D434D;} */
  /* .showOdds .head-control {
      border-right: 1px solid #3D434D;
  } */
  /* .showOdds .head-control:not(:first-child) {border-top: 1px solid #3D434D;} */
  .showOdds > .head-control:last-child {
    border-bottom: 0;
  }

  /* end new */
  .teambox__odds {
    position: static;
    transform: inherit;
    flex-direction: column !important;
  }

  .teambox__odds .company {
    flex-direction: row;
    width: auto;
    margin: 0px;
    padding: 10px;
    color: #4FA747;
    font-family: 'Oswald';
    font-size: 0.6125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
    /* 138.462% */
    text-transform: uppercase;
  }

  .teambox__odds .company .head {
    text-indent: inherit;
  }

  .teambox__odds .company .pointer {
    margin-bottom: 0px
  }

  .teambox__odds .state {
    display: none;
  }

  .teambox {
    min-height: auto;
  }
  .showOdds {
    gap: 0px;
  }
  .teambox__odds .box {
    border-bottom: 0px;
  }
  div[data-type="1"] {
    margin-bottom: 5px !important;
  }
  div[data-type="1"].state .box {
    border: 1px solid #25b7d3;
    border-top: 0px;
  }
  div[data-type="1"] .col {
    border-right: 1px solid #25b7d3;
    border-bottom: 1px solid #25b7d3;
  }
  div[data-type="1"] {
    border-top: 1px solid #25b7d3;
  }
  div[data-type="1"].head-control {
    border: 1px solid #25b7d3;
    border-right: 0px;
  }
  span[data-type="1"].active {
    background-color: #25b7d3 !important;
  }

  div[data-type="2"] {
    margin-bottom: 5px !important;
  }
  div[data-type="2"].state .box {
    border: 1px solid #8dc642;
    border-top: 0px;
  }
  div[data-type="2"] .col {
    border-right: 1px solid #8dc642;
    border-bottom: 1px solid #8dc642;
  }
  div[data-type="2"] {
    border-top: 1px solid #8dc642;
  }
  div[data-type="2"].head-control {
    border: 1px solid #8dc642;
    border-right: 0px;
  }
  span[data-type="2"].active {
    background-color: #8dc642 !important;
  }

  div[data-type="3"] {
    margin-bottom: 5px !important;
  }
  div[data-type="3"].state .box {
    border: 1px solid #eb4095;
    border-top: 0px;
  }
  div[data-type="3"] .col {
    border-right: 1px solid #eb4095;
    border-bottom: 1px solid #eb4095;
  }
  div[data-type="3"] {
    border-top: 1px solid #eb4095;
  }
  div[data-type="3"].head-control {
    border: 1px solid #eb4095;
    border-right: 0px;
  }
  span[data-type="3"].active {
    background-color: #eb4095 !important;
  }

  div[data-type="4"] {
    margin-bottom: 5px !important;
  }
  div[data-type="4"].state .box {
    border: 1px solid #ffc107;
    border-top: 0px;
  }
  div[data-type="4"] .col {
    border-right: 1px solid #ffc107;
    border-bottom: 1px solid #ffc107;
  }
  div[data-type="4"] {
    border-top: 1px solid #ffc107;
  }
  div[data-type="4"].head-control {
    border: 1px solid #ffc107;
    border-right: 0px;
  }
  .teambox__odds .table:last-child {
    border-top: 1px solid #ffc107;
  }
  span[data-type="4"].active {
    background-color: #ffc107 !important;
  }
  .teambox__odds .box {
    min-height: 24px;
  }
  .teambox__odds .state span, .teambox__odds .box .col {
    font-size: 0.6875rem;
  }
  /* div[data-type="4"] .box {border-color: #ffc107;} */
  /* div[data-type="2"] {background-color: #9EF8EE !important;}
  div[data-type="3"] {background-color: #FA7F08 !important;}
  div[data-type="4"] {background-color: #F24405 !important;} */
}

.green {
  color: #009B3A !important;
}

.orange {
  color: #FF361E !important
}


.orange:after {
  content: '';
  background: url('https://xoilaczvu.tv/wp-content/themes/bongda/dist/images/icon-arrow-down.png') no-repeat top;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 4px;
}

@media screen and (max-width: 991px) {
  .table .head {
    display: none;
  }

  .teambox__odds {
    margin-left: -15px;
    margin-right: -15px;
  }

  .marquee-container {
    margin: 0px -15px;
    width: auto;
  }

  .teambox__odds .showOdds {
    //grid: 3;
    grid-template-columns: 5.25rem 3.125rem 1fr;
  }

  .teambox__odds--type span {
    color: #FFF;
    font-family: 'Oswald';
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    /* 163.636% */
    text-transform: uppercase;
    border: 1px solid #1C1E1F;
    background: #292B2E;
    padding: 0.25rem 0.38rem;
    cursor: pointer;
  }

  .teambox__odds--type span.active {
    background: #009139;
    box-shadow: 0px 2px 12px rgba(79, 167, 71, 0.20);
  }

  .teambox__odds .company {
    margin-bottom: 0px !important;
    cursor: pointer;
  }

  .match-single-top {
    //background: none;
    padding: 0px 15px;
  }
}





.marquee-container {
  position: relative;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #000;
  text-align: left;
  background: #ffc107;
  border-radius: 4px;
  overflow: hidden
}

.detailTop {
  display: flex;
}

.marquee-container:hover .marquee-box {
  animation-play-state: paused
}

.marquee-container .marquee-box {
  position: absolute;
  display: flex;
  gap: 60px;
  white-space: nowrap;
  animation: marquee 15s linear infinite
}

.marquee-container div, .marquee-container p {
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle;
  font-size: 17px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.marquee-container div:last-child, .marquee-container p:last-child {
  margin-right: 0
}

.marquee-container a {
  color: #dc3545;
  text-decoration: underline;
  font-weight: bold;
}

.marquee-container img {
  width: 20px;
  height: 20px;
  vertical-align: sub
}

@keyframes marquee {
  0% {
    left: 100%
  }

  100% {
    left: 0;
    transform: translatex(-100%)
  }
}

.odds-header {
  background: #292B2E;
  padding: 0.38rem 0.62rem;
}

.teambox__odds--mb .oddContent span {
  color: #4FA747;
  font-family: Oswald;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  text-transform: uppercase;
}

.teambox__odds--mb .odds-type {
  display: flex;
  gap: 0.5rem;
}

.teambox__odds--mb .odds-type span {
  color: #FFF;
  font-family: 'Oswald';
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  /* 163.636% */
  text-transform: uppercase;
  padding: 0.25rem 0.38rem;
  background: #292B2E;
  border: 1px solid rgba(28, 30, 31, 1);
  border-radius: 2px;
}

.teambox__odds--mb .odds-type span.active {
  border-color: #009139;
  color: #009139;
  box-shadow: 0px 2px 12px rgba(79, 167, 71, 0.20);
}

.teambox__odds {
  background: #212324;
  border-radius: 6px;
  border: 1px solid #3D434D;
}

.oddContent {
  position: relative;
}

.oddContent > span {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.oddContent .oddList {
  background-color: #fff;
  position: absolute;
  z-index: 10;
  top: 35px;
  left: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.oddContent .oddList li {
  width: 115px;
  border-bottom: 1px solid #e2e2e2;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 10px;
}

.oddType {
  max-width: 115px;
}

.teambox__odds .table {
  border-right: 1px solid #3D434D;
}

.teambox__odds .table:last-child {
  border: 0px;
}

.teambox__odds .company {
  flex-direction: column;
  padding: 10px 20px;
  flex: none;
  width: 160px;
}

.teambox__odds .company .head {
  padding: 0px 14px;
}

.teambox__odds .company select {
  background: transparent;
  border: 0px;
  outline: none;
  color: #FFF;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.teambox__odds .company .pointer {
  margin-bottom: 15px;
  width: 100%;
}

.teambox__odds .company .pointer:last-child {
  margin-bottom: 0;
}

.teambox__odds .state {
  width: 56px;
  flex: none;
}

.teambox__odds .state span {
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}

.teambox__odds .head {
  background: #292B2E;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 42px;
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 153.846% */
  text-transform: uppercase;
  width: 100%;
  border-bottom: 1px solid #3D434D;
}

.teambox__odds .box {
  min-height: 42px;
  color: #FFF;
  text-align: center;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  justify-content: center;
  display: flex;
  border-bottom: 1px solid #3D434D;
  margin: 0px;
}

.teambox__odds .type .box {
  height: 84px;
}

.teambox__odds .box:last-child {
  border-bottom: 0px;
}

.teambox__odds .box .col {
  align-items: center;
  text-align: center;
  flex: 1;
  justify-content: center;
  display: flex;
  font-family: 'Oswald';
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
}

@media screen and (max-width: 991px) {
  .oddType {
    max-width: 60px;
  }
  /* new */
  /* .showOdds .table {border-top: 1px solid #3D434D;border-bottom: 0px;} */
  .head.active::before {
    display: none;
  }

  .showOdds {
    grid-gap: 1px;
    background: #3D434D;
    border-top: 1px solid #3D434D;
  }

  .showOdds .table {
    border-bottom: 0;
    border-right: 0px;
  }

  .showOdds .h-on {
    height: 0;
    opacity: 0;
    overflow: hidden;
    margin-bottom: 0px !important;
  }

  .showOdds .h-off {
    height: auto;
    opacity: 1;
  }

  .teambox__odds .showOdds > * {
    background-color: #292B2E;
    /* màu nền của các phần tử con */
  }

  .showOdds .head-control span {
    background: none;
    color: #FFF;
    font-family: 'Raleway';
    font-size: 0.6125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    /* 138.462% */
  }

  /* .teambox__odds .showOdds {border-top: 1px solid #3D434D;} */
  /* .showOdds .head-control {
      border-right: 1px solid #3D434D;
  } */
  /* .showOdds .head-control:not(:first-child) {border-top: 1px solid #3D434D;} */
  .showOdds > .head-control:last-child {
    border-bottom: 0;
  }

  /* end new */
  .teambox__odds {
    position: static;
    transform: inherit;
    flex-direction: column !important;
  }

  .teambox__odds .company {
    flex-direction: row;
    width: auto;
    margin: 0px;
    padding: 10px;
    color: #4FA747;
    font-family: 'Oswald';
    font-size: 0.6125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
    /* 138.462% */
    text-transform: uppercase;
  }

  .teambox__odds .company .head {
    text-indent: inherit;
  }

  .teambox__odds .company .pointer {
    margin-bottom: 0px
  }

  .teambox__odds .state {
    display: none;
  }

  .teambox {
    min-height: auto;
  }
  .showOdds {
    gap: 0px;
  }
  .teambox__odds .box {
    border-bottom: 0px;
  }
  div[data-type="1"] {
    margin-bottom: 5px !important;
  }
  div[data-type="1"].state .box {
    border: 1px solid #25b7d3;
    border-top: 0px;
  }
  div[data-type="1"] .col {
    border-right: 1px solid #25b7d3;
    border-bottom: 1px solid #25b7d3;
  }
  div[data-type="1"] {
    border-top: 1px solid #25b7d3;
  }
  div[data-type="1"].head-control {
    border: 1px solid #25b7d3;
    border-right: 0px;
  }
  span[data-type="1"].active {
    background-color: #25b7d3 !important;
  }

  div[data-type="2"] {
    margin-bottom: 5px !important;
  }
  div[data-type="2"].state .box {
    border: 1px solid #8dc642;
    border-top: 0px;
  }
  div[data-type="2"] .col {
    border-right: 1px solid #8dc642;
    border-bottom: 1px solid #8dc642;
  }
  div[data-type="2"] {
    border-top: 1px solid #8dc642;
  }
  div[data-type="2"].head-control {
    border: 1px solid #8dc642;
    border-right: 0px;
  }
  span[data-type="2"].active {
    background-color: #8dc642 !important;
  }

  div[data-type="3"] {
    margin-bottom: 5px !important;
  }
  div[data-type="3"].state .box {
    border: 1px solid #eb4095;
    border-top: 0px;
  }
  div[data-type="3"] .col {
    border-right: 1px solid #eb4095;
    border-bottom: 1px solid #eb4095;
  }
  div[data-type="3"] {
    border-top: 1px solid #eb4095;
  }
  div[data-type="3"].head-control {
    border: 1px solid #eb4095;
    border-right: 0px;
  }
  span[data-type="3"].active {
    background-color: #eb4095 !important;
  }

  div[data-type="4"] {
    margin-bottom: 5px !important;
  }
  div[data-type="4"].state .box {
    border: 1px solid #ffc107;
    border-top: 0px;
  }
  div[data-type="4"] .col {
    border-right: 1px solid #ffc107;
    border-bottom: 1px solid #ffc107;
  }
  div[data-type="4"] {
    border-top: 1px solid #ffc107;
  }
  div[data-type="4"].head-control {
    border: 1px solid #ffc107;
    border-right: 0px;
  }
  .teambox__odds .table:last-child {
    border-top: 1px solid #ffc107;
  }
  span[data-type="4"].active {
    background-color: #ffc107 !important;
  }
  .teambox__odds .box {
    min-height: 24px;
  }
  .teambox__odds .state span, .teambox__odds .box .col {
    font-size: 0.6875rem;
  }
  /* div[data-type="4"] .box {border-color: #ffc107;} */
  /* div[data-type="2"] {background-color: #9EF8EE !important;}
  div[data-type="3"] {background-color: #FA7F08 !important;}
  div[data-type="4"] {background-color: #F24405 !important;} */
}

.green {
  color: #009B3A !important;
}

.orange {
  color: #FF361E !important
}


.orange:after {
  content: '';
  background: url('https://xoilaczmm.tv/wp-content/themes/bongda/dist/images/icon-arrow-down.png') no-repeat top;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-left: 4px;
}

@media screen and (max-width: 991px) {
  .table .head {
    display: none;
  }

  .teambox__odds {
    margin-left: -15px;
    margin-right: -15px;
  }

  .marquee-container {
    margin: 0px -15px;
    width: auto;
  }

  .teambox__odds .showOdds {
    grid-template-columns: 5.25rem 3.125rem 1fr;
  }

  .teambox__odds--type span {
    color: #FFF;
    font-family: 'Oswald';
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    /* 163.636% */
    text-transform: uppercase;
    border: 1px solid #1C1E1F;
    background: #292B2E;
    padding: 0.25rem 0.38rem;
    cursor: pointer;
  }

  .teambox__odds--type span.active {
    background: #009139;
    box-shadow: 0px 2px 12px rgba(79, 167, 71, 0.20);
  }

  .teambox__odds .company {
    margin-bottom: 0px !important;
    cursor: pointer;
  }

  .match-single-top {
    //background: none;
    padding: 0px 15px;
  }
}
.block2{
  div{
    display: flex;
    justify-content: center;
  }
}
</style>