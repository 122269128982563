
import Vue from 'vue';
import App from './APP.vue';
import VueRouter from 'vue-router';
import detailPage from './components/detailPage.vue';
import Home from './components/homePage.vue';
import oddsPage from './components/oddsPage.vue';
import kqbdPage from './components/kqbdPage.vue';
import rankPage from './components/rankPage.vue';
import liveScore from './components/liveScore.vue';
import countDown from './components/countDown.vue';


import headerLayout from './layout/header.vue';

import AxiosPlugin from './axios.js';
Vue.use(AxiosPlugin);
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: headerLayout, // 使用布局组件
    children: [
      { path: '/', component: Home,name:'Home'},
      { path: '/detail', component: detailPage,name:'detailPage' },
      { path: '/odds', component: oddsPage,name:'oddsPage' },
      { path: '/kqbd', component: kqbdPage,name:'kqbdPage' },
      { path: '/rank', component: rankPage,name:'rankPage' },
      { path: '/liveScore', component: liveScore,name:'liveScore' },
      { path: '/countdown', component: countDown,name:'countDown' }
    ]
  }

];

const router = new VueRouter({
  mode: 'history',
  routes
});


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
