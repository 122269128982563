<script >
export default {
  name: 'headerLayout',
  // 其他布局设置
  data(){
    return{
      isActive:[],
      isShow: false
    }
  },
  methods:{
    toggleShow() {
      this.isShow = !this.isShow;
    },
    goPage(item,index) {
      this.$router.push({ name: item});
      this.isActive=index
      this.isShow = false
    },
  }
}
</script>

<template>
  <div>
<!--    <header>-->
<!--      <nav class="navbar navbar-expand-md main-menu py-2 mb-2">-->
<!--        <div class="container" style="width: 90%">-->

<!--          <button @click="toggleShow" class="navbar-toggler" type="button" data-toggle="collapse"-->
<!--                  data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"-->
<!--                  aria-expanded="false" aria-label="Toggle navigation">-->
<!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">-->
<!--              <path d="M22.7916 7.71859H1.20843C0.852458 7.71859 0.562062 7.83417 0.337237 8.06533C0.112411 8.29648 0 8.60301 0 8.98492C0 9.38694 0.112411 9.70352 0.337237 9.93467C0.562062 10.1658 0.852458 10.2814 1.20843 10.2814H22.7916C23.1475 10.2814 23.4379 10.1658 23.6628 9.93467C23.8876 9.70352 24 9.38694 24 8.98492C24 8.60301 23.8876 8.29648 23.6628 8.06533C23.4379 7.83417 23.1475 7.71859 22.7916 7.71859ZM1.20843 2.56281H22.7916C23.1475 2.56281 23.4379 2.44724 23.6628 2.21608C23.8876 1.98492 24 1.66834 24 1.26633C24 0.88442 23.8876 0.577891 23.6628 0.346734C23.4379 0.115577 23.1475 0 22.7916 0H1.20843C0.852457 0 0.562062 0.115577 0.337237 0.346734C0.112411 0.577891 0 0.88442 0 1.26633C0 1.66834 0.112411 1.98492 0.337237 2.21608C0.562062 2.44724 0.852457 2.56281 1.20843 2.56281ZM22.7916 15.4372H1.20843C0.852457 15.4372 0.562062 15.5528 0.337237 15.7839C0.112411 16.0151 0 16.3216 0 16.7035C0 17.1055 0.112411 17.4221 0.337237 17.6533C0.562062 17.8844 0.852457 18 1.20843 18H22.7916C23.1475 18 23.4379 17.8844 23.6628 17.6533C23.8876 17.4221 24 17.1055 24 16.7035C24 16.3216 23.8876 16.0151 23.6628 15.7839C23.4379 15.5528 23.1475 15.4372 22.7916 15.4372Z"-->
<!--                    fill="#009B3A"/>-->
<!--            </svg>-->
<!--          </button>-->

<!--          <div :class="{ 'show': isShow }" class="collapse navbar-collapse " id="navbarSupportedContent">-->
<!--            &lt;!&ndash; Left Side Of Navbar &ndash;&gt;-->
<!--            <ul id="menu-main-menu" class="navbar-nav mr-auto">-->
<!--              <li  class=" nav-item menu-item menu-trang-chu">-->
<!--                <a class="nav-link"-->
<!--                   :class="{green: isActive === 1}"-->
<!--                   @click="goPage('Home',1)"-->
<!--                   aria-current="page">Trang Chủ</a></li>-->
<!--              <li  class="nav-item menu-item menu-lich-thi-dau"><a @click="goPage('liveScore',2)" :class="{green: isActive === 2}" class="nav-link"-->
<!--                                                                >Lịch-->
<!--                Thi Đấu</a></li>-->
<!--              <li  class="nav-item menu-item menu-bang-xep-hang"><a :class="{green: isActive === 3}" class="nav-link"-->
<!--                                                                   @click="goPage('rankPage',3)"  >Bảng-->
<!--                Xếp Hạng</a></li>-->
<!--              <li  class="nav-item menu-item menu-ty-le-keo"><a :class="{green: isActive === 4}" class="nav-link"-->
<!--                                                               @click="goPage('oddsPage',4)">Tỷ Lệ-->
<!--                Kèo</a></li>-->
<!--              <li  class="nav-item menu-item menu-kqbd"><a :class="{green: isActive === 5}" class="nav-link"-->
<!--                                                          @click="goPage('kqbdPage',5)">KQBD</a></li>-->
<!--&lt;!&ndash;              <li class="nav-item menu-item menu-bongdalu-vip"><a class="nav-link"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                  href="https://xoilaczz17.live/bongdalu-vip/">Bongdalu&ndash;&gt;-->
<!--&lt;!&ndash;                Vip</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li  class="nav-item menu-item menu-livescore"><a :class="{green: isActive === 6}" class="nav-link"&ndash;&gt;-->
<!--&lt;!&ndash;                                                               >Livescore</a>&ndash;&gt;-->
<!--&lt;!&ndash;              </li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="nav-item menu-item menu-tin-tuc"><a class="nav-link"&ndash;&gt;-->
<!--&lt;!&ndash;                                                             href="https://xoilaczz17.live/tin-tuc-moi-nhat/">Tin&ndash;&gt;-->
<!--&lt;!&ndash;                Tức</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="nav-item menu-item menu-soi-keo"><a class="nav-link"&ndash;&gt;-->
<!--&lt;!&ndash;                                                             href="https://xoilaczz17.live/soi-keo/">Soi&ndash;&gt;-->
<!--&lt;!&ndash;                kèo</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="mr-2 nav-item menu-item menu-top-nha-cai"><a class="nav-link" rel="nofollow"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                      href="https://xoilaczz17.live/top-nha-cai/">Top&ndash;&gt;-->
<!--&lt;!&ndash;                nhà cái</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li class="mr-2 style-4 nav-item menu-item menu-cuoc-8xbet"><a class="nav-link" target="_blank"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                             rel="nofollow"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                             href="https://276863.com">Cuợc&ndash;&gt;-->
<!--&lt;!&ndash;                8XBET</a></li>&ndash;&gt;-->
<!--            </ul>-->
<!--            &lt;!&ndash; Right Side Of Navbar &ndash;&gt;-->
<!--            <ul class="navbar-nav ml-auto"></ul>-->
<!--          </div>-->
<!--        </div>-->
<!--      </nav>-->
<!--    </header>-->
<!--    <div style="height: 30px "></div>-->
    <router-view></router-view>
  </div>
</template>

<style scoped lang="scss">
@media (max-width: 760px) {
  .navbar-nav{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
.nav-item{
margin-top: 10px;
  height: 50px;
}
  }
}
.green{
color:green!important;
}
.nav-item{
  cursor: pointer;
}
//header {
//  height: 60px;
//  background-color: #333;
//  color: #fff;
//  /* 其他样式设置 */
//}
</style>