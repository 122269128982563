<template>
  <div class="body">
    <div class="top">
      <div class="top1" @click="a()">
        Trang chủ / Lịch Thi Đấu
        <br>
        <br>

        <h5>
          LỊCH THI ĐẤU NHANH NHẤT - LỊCH BÓNG ĐÁ 24H HÔM NAY

        </h5>
      </div>
      <div class="content-page-schedule">
        <div class="d-flex sports-bars">
          <ul class="nav nav-tabs d-flex justify-content-center justify-content-md-start" role="tablist">
            <li class="nav-item" role="presentation">
              <button :class="{active: activeButton === 1}" @click="setActiveButton(1)" class="nav-link   "
                      id="livescore-tab" data-toggle="tab" data-target="#livescore" type="button" role="tab"
                      aria-controls="livescore" aria-selected="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.0005 8.92538C12.0005 8.06738 11.7132 7.27864 11.2293 6.64705C10.9774 6.31825 11.0397 5.84749 11.3685 5.59556C11.6973 5.34364 12.168 5.40595 12.42 5.73474C13.0975 6.61897 13.5005 7.72621 13.5005 8.92538C13.5005 10.1752 13.0628 11.3249 12.333 12.2264C12.0724 12.5484 11.6001 12.5981 11.2781 12.3375C10.9562 12.0769 10.9064 11.6046 11.1671 11.2827C11.6886 10.6384 12.0005 9.81931 12.0005 8.92538Z"
                        fill="#FF0B0B"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6 8.92537C6 8.06625 6.28798 7.27656 6.77306 6.64456C7.02526 6.31597 6.96334 5.84515 6.63476 5.59295C6.30617 5.34075 5.83536 5.40267 5.58316 5.73125C4.90403 6.61606 4.5 7.72462 4.5 8.92537C4.5 10.1742 4.937 11.323 5.66578 12.2243C5.92622 12.5464 6.39846 12.5964 6.72055 12.3359C7.04264 12.0755 7.09261 11.6032 6.83217 11.2812C6.31138 10.6371 6 9.81862 6 8.92537Z"
                        fill="#FF0B0B"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.5 8.92522C7.5 9.75367 8.17155 10.4252 9 10.4252C9.82845 10.4252 10.5 9.75367 10.5 8.92522C10.5 8.09684 9.82845 7.42523 9 7.42523C8.17155 7.42523 7.5 8.09684 7.5 8.92522Z"
                        fill="#FF8B8B"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15 8.92522C15 7.25119 14.3916 5.72117 13.3828 4.54135C13.1137 4.22652 13.1507 3.7531 13.4655 3.48392C13.7804 3.21475 14.2537 3.25175 14.5229 3.56659C15.7552 5.0078 16.5 6.88048 16.5 8.92522C16.5 11.017 15.7205 12.9286 14.4373 14.3824C14.1632 14.693 13.6892 14.7226 13.3787 14.4484C13.0682 14.1743 13.0386 13.7004 13.3127 13.3898C14.3633 12.1995 15 10.6376 15 8.92522Z"
                        fill="#FF8B8B"></path>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M3 8.9253C3 7.25138 3.60836 5.72145 4.61696 4.54166C4.88612 4.22682 4.84909 3.75339 4.53425 3.48423C4.21941 3.21507 3.74598 3.2521 3.47682 3.56694C2.24474 5.00812 1.5 6.8807 1.5 8.9253C1.5 11.0143 2.27737 12.9235 3.55751 14.3767C3.83132 14.6875 4.30524 14.7175 4.61606 14.4437C4.92686 14.1698 4.95686 13.6959 4.68306 13.3851C3.63495 12.1954 3 10.6354 3 8.9253Z"
                        fill="#FF8B8B"></path>
                </svg>
                &nbsp;<span style="color: white">Livescore</span>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button :class="{active: activeButton === 2}" @click="setActiveButton(2)" class="nav-link "
                      id="football-tab" data-toggle="tab" data-target="#football" type="button" role="tab"
                      aria-controls="football" aria-selected="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g clip-path="url(#clip0_3_4051)">
                    <path
                        d="M9 0C4.02963 0 0 4.02963 0 9C0 13.9704 4.02963 18 9 18C13.9704 18 18 13.9704 18 9C18 4.02963 13.9704 0 9 0Z"
                        fill="white"></path>
                    <path
                        d="M9 15.1364C4.51421 15.1364 0.805737 11.826 0.122684 7.5C0.0492631 7.96773 0 8.44357 0 8.93182C0 13.9398 4.02963 18 9 18C13.9704 18 18 13.9398 18 8.93182C18 8.44357 17.9507 7.96773 17.8773 7.5C17.1943 11.826 13.4858 15.1364 9 15.1364Z"
                        fill="#E1E9ED"></path>
                    <path d="M9.00041 5.10776L5.21094 7.86081L6.65852 12.3158H11.3423L12.7899 7.86081L9.00041 5.10776Z"
                          fill="#37474F"></path>
                    <path
                        d="M5.90234 0.559421L9.00119 2.15479L12.1 0.559421C11.1328 0.203684 10.0921 0 9.00119 0C7.91029 0 6.86961 0.203684 5.90234 0.559421Z"
                        fill="#37474F"></path>
                    <path
                        d="M16.0706 3.44461L15.5107 6.88498L17.9857 9.33914C18.0246 8.30935 17.8972 7.25635 17.5599 6.21898C17.2231 5.18161 16.7077 4.25461 16.0706 3.44461Z"
                        fill="#37474F"></path>
                    <path
                        d="M0.0155442 9.33914L2.49054 6.88498L1.93065 3.44461C1.29354 4.25508 0.778176 5.18161 0.440913 6.21898C0.103649 7.25635 -0.0232979 8.30887 0.0155442 9.33914Z"
                        fill="#37474F"></path>
                    <path
                        d="M6.54665 17.6504L4.97733 14.5383L1.53223 14.0078C2.10586 14.8642 2.82823 15.6406 3.7107 16.282C4.59317 16.9233 5.55475 17.3691 6.54665 17.6504Z"
                        fill="#37474F"></path>
                    <path
                        d="M16.4695 14.0073L13.0244 14.5378L11.4551 17.6499C12.4465 17.369 13.4086 16.9219 14.291 16.281C15.1735 15.6401 15.8954 14.8632 16.4695 14.0073Z"
                        fill="#37474F"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_3_4051">
                      <rect width="18" height="18" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                &nbsp; <span style="color: white">Lịch thi đấu</span>
              </button>
            </li>
          </ul>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade  show active " id="livescore" role="tabpanel" aria-labelledby="livescore-tab">
          </div>
          <div class="as-pt-12 tab-pane fade " id="football" role="tabpanel" aria-labelledby="football-tab">
            <div id="schedule-app" class="as-bg-black"></div>
          </div>
        </div>
      </div>
      <div v-show="currentPage==='Lich'" class="container">
        <div class="containerTop">
          <div style="font-size: 16px" v-for="(item,index) in countries" :key="index">
            <div style="font-size: 13px"
                 :class="{ 'item': true, 'green': selectedIndex === index }"
                 @click="changeCountry(item.country_id,index)"
                 class="item" v-if="countryId.includes(item.country_id)">{{ item.country_name2 }}
            </div>

          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 100 }"
               @click="changeLeague('3',100)"
               class="item">C1
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 101 }"
               @click="changeLeague('4',101)"
               class="item">C2
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 102 }"
               @click="changeLeague('683',102)"
               class="item">C3
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 103 }"
               @click="changeLeague('339',103)"
               class="item">V LEAGUE
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 104 }"
               @click="changeLeague('401',104)"
               class="item">Cup
          </div>
          <div style="font-size: 16px"
               :class="{ 'item': true, 'green': selectedIndex === 105 }"
               @click="changeLeague('470',105)"
               class="item">Super Cup
          </div>
        </div>
      </div>

      <div style="height: 20px"></div>

      <div v-show="currentPage==='Lich'" class="container">
        <div class="containerTop">
          <div :class="{ 'item': true, 'green': dateIndex === 1 }" @click="changeDate(1,7,1)" class="item">Tuần tới
          </div>
          <div :class="{ 'item': true, 'green': dateIndex === 2 }" @click="changeDate(0,0,2)" class="item">Hôm nay</div>
          <div :class="{ 'item': true, 'green': dateIndex === 3 }" @click="changeDate(1,1,3)" class="item">{{
              tomorrow
            }}
          </div>
          <div :class="{ 'item': true, 'green': dateIndex === 4 }" @click="changeDate(2,2,4)" class="item">
            {{ twoDaysLater }}
          </div>
          <div :class="{ 'item': true, 'green': dateIndex === 5 }" @click="changeDate(3,3,5)" class="item">
            {{ threeDaysLater }}
          </div>
          <div :class="{ 'item': true, 'green': dateIndex === 6 }" @click="changeDate(4,4,6)" class="item">
            {{ fourDaysLater }}
          </div>
          <div :class="{ 'item': true, 'green': dateIndex === 7 }" @click="changeDate(5,5,7)" class="item">
            {{ fiveDaysLater }}
          </div>
          <div :class="{ 'item': true, 'green': dateIndex === 8 }" @click="changeDate(6,6,8)" class="item">
            {{ sixDaysLater }}
          </div>

        </div>
      </div>

      <div id="loading" class="loading" v-if="loading"></div>
      <div v-show="currentPage==='liveScore'" @click="a(item.match)" class="matchBox2">
        <div>
          <div class="matchBox-title">
            <div class="matchBox-title-left">
              <span>Giờ</span>
            </div>
            <div class="matchBox-title-center">
              <div>Chủ nhà</div>
              <div>Tỷ số</div>
              <div>Khách</div>
            </div>
            <div class="matchBox-title-right">
              <div>HT</div>
              <!--              <div>8</div>-->
            </div>
            <div class="matchBox-title-odds">
              <div>odds</div>
              <!--              <div>8</div>-->
            </div>
          </div>

          <div v-for="(match, index) in leagues" :key="index" class="matchBox2">
            <div class="matchBox-league">

              &nbsp;&nbsp;  <div><img :src=match.league_logo></div>&nbsp;&nbsp;
              <div>{{ match.league_name }}</div>
            </div>
            <div v-for="(match, index) in match.matches" :key="index" class="matchBox-container">
              <div class="matchBox-container-left"> {{ match.match_time }}</div>
              <div class="matchBox-container-center">
                <div
                    style="margin-right: 2%; align-items: center; width: 40%;justify-content: flex-start; display: flex">
                  <img :src=match.team_home_badge style="width: 30px">&nbsp;
                  <span>{{ match.match_hometeam_name }}</span></div>
                <div style="background-color: #0d57e9;">
                  {{ match.match_hometeam_score }}&nbsp;:&nbsp;{{ match.match_awayteam_score }}
                </div>
                <div style="align-items: center;margin-left: 3%;width: 40%;justify-content: flex-end; display: flex">
                  <span>{{ match.match_awayteam_name }}</span> &nbsp;<img :src=match.team_away_badge
                                                                          style="width: 30px"></div>
              </div>
              <div class="matchBox-container-right">
                <div style=" background-color: #8B0000 ;display: flex;justify-content: center">
                  {{ match.match_hometeam_halftime_score }}&nbsp;:&nbsp;{{ match.match_awayteam_halftime_score }}
                </div>
              </div>
              <div class="matchBox-container-odds">
                <div>
                  <div v-if="match['ah+1.5_1']" class="item"
                       style="color:#ac4373;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>{{ match['ah+1.5_1'] }}</div>
                      <div>1.5</div>
                      <div>{{ match['ah+1.5_2'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['ah+2.5_1']" class="item"
                       style="color: #ac4373;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>{{ match['ah+2.5_1'] }}</div>
                      <div>2.5</div>
                      <div> {{ match['ah+2.5_2'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['ah+3.5_1']" class="item"
                       style="color: #ac4373;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>{{ match['ah+3.5_1'] }}</div>
                      <div> 3.5</div>
                      <div> {{ match['ah+3.5_2'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['ah+4.5_1']" class="item"
                       style="color: #ac4373;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>{{ match['ah+4.5_1'] }}</div>
                      <div>4.5</div>
                      <div>{{ match['ah+4.5_2'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['ah+0.5_1']" class="item"
                       style="color: #ac4373;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>{{ match['ah+0.5_1'] }}</div>
                      <div>0.5</div>
                      <div>{{ match['ah+0.5_2'] }}</div>
                    </div>

                  </div>
                  <div v-else class="item"
                       style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>{{ match['ah0_1'] }}</div>
                      <div>0</div>
                      <div> {{ match['ah0_2'] }}</div>

                    </div>
                  </div>
                </div>

                <div>
                  <div v-if="match['o+2.5']" class="item"
                       style="color: #7aa4e2;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>O {{ match['o+2.5'] }}</div>
                      <div>2.5</div>
                      <div>U {{ match['u+2.5'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['o+1.5']" class="item"
                       style="color: #FF4C13;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>O {{ match['o+1.5'] }}</div>
                      <div>2.5</div>
                      <div>U {{ match['u+1.5'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['o+3.5']" class="item"
                       style="color: #7aa4e2;;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>O {{ match['o+3.5'] }}</div>
                      <div>3.5</div>
                      <div>U {{ match['u+3.5'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['o+0.5']" class="item"
                       style="color: #7aa4e2;;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>O {{ match['o+0.5'] }}</div>
                      <div>0.5</div>
                      <div>U {{ match['u+0.5'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['o+4.5']" class="item"
                       style="color: #7aa4e2;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>O {{ match['o+4.5'] }}</div>
                      <div>4.5</div>
                      <div>U {{ match['u+4.5'] }}</div>
                    </div>
                  </div>
                  <div v-else-if="match['o+5.5']" class="item"
                       style="color: #7aa4e2;flex-basis: 17%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                    <div class="block2">
                      <div>O {{ match['o+4.5'] }}</div>
                      <div>2.5</div>
                      <div>U {{ match['u+4.5'] }}</div>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>


        </div>
      </div>


      <div v-show="currentPage==='Lich'" @click="a(item.match)" class="matchBox" v-for="(item, index) in list"
           :key="index">
        <div v-if="Array.isArray(item.match)">
          <div class="matchBox-title">
            <div class="matchBox-title-left">
              <img :src=item.league_logo style="width: 30px"> <span>{{ item.league_name }}</span>
            </div>
            <div v-if="false" class="matchBox-title-center">
              <div>Chủ nhà</div>
              <div>Tỷ số</div>
              <div>Khách</div>
            </div>
            <div v-if="false" class="matchBox-title-right">
              <div>HT</div>
              <!--              <div>8</div>-->
            </div>
          </div>
          <div v-for="(match, index) in item.match" :key="index" class="matchBox-container">
            <div class="matchBox-container-left">{{
                match.match_date
              }}&nbsp;&nbsp;{{ match.match_time }}
            </div>
            <div class="matchBox-container-center">
              <div style="margin-right: 2%; align-items: center; width: 40%;justify-content: flex-start; display: flex">
                <img :src=match.team_home_badge style="width: 30px">&nbsp; <span>{{ match.match_hometeam_name }}</span>
              </div>
              <div style="font-size: 20px;background-color: #0A7FD0">VS</div>
              <div style="align-items: center;margin-left: 3%;width: 40%;justify-content: flex-end; display: flex">
                <span>{{ match.match_awayteam_name }}</span> &nbsp;<img :src=match.team_away_badge style="width: 30px">
              </div>
            </div>
            <div class="matchBox-container-right">
              <!--              <div style=" background-color: #8B0000 ;display: flex;justify-content: center" >{{match.match_hometeam_halftime_score}}&nbsp;:&nbsp;{{match.match_awayteam_halftime_score}}</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {

    return {
      leagues: [],
      currentPage: 'liveScore',
      activeButton: 1,
      total: [],
      countryId: [6, 44, 4, 5, 3, 95, 17, 92, 64, 32, 68, 14, 27, 78, 82, 39, 40, 23, 87, 106, 108, 59, 42],
      date1: '',
      date2: '',
      country: 44,
      countries: [],
      loading: false,
      currentDate: new Date(),
      league_name: [],
      // tomorrow: [],
      list: [],
      chunkSize: 10, // 每次加载的数量
      loadedItems: 10, // 已加载的数量
      selectedIndex: 47,
      dateIndex: 1

    };
  },
  computed: {
    today() {
      return this.formatDate(0);
    },
    tomorrow() {
      return this.formatDate(1);
    },
    twoDaysLater() {
      return this.formatDate(2);
    },
    threeDaysLater() {
      return this.formatDate(3);
    },
    fourDaysLater() {
      return this.formatDate(4);
    },
    fiveDaysLater() {
      return this.formatDate(5);
    },
    sixDaysLater() {
      return this.formatDate(6);
    },
    nextWeek() {
      return this.formatDate(7);
    },
    displayedData() { // 计算属性用于返回要显示在页面上的颜色数组
      return this.total.slice(0, this.loadedItems);
    }
  },
  created() {
    this.countryId = this.countryId.map(number => number.toString());
    this.fetchFootballLiveScore()
    this.changeDate(1, 7, 1)
    this.fetchFootballCountries();
  },
  methods: {

    changeLeague(leagueId, index) {
      this.selectedIndex = index;
      this.fetchFootballLeague(leagueId);
    },
    fetchFootballLeague(league_id) {
      this.currentPage = 'Lich'
      this.list = [{match: {}}]
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd'
      axios.get(`https://apiv3.apifootball.com/?action=get_events&from=${this.date1}&to=${this.date2}&league_id=${league_id}&APIkey=${APIkey}`)
          .then(response => {

            // console.log(response)

            this.list[0].league_name = response.data[0].league_name
            this.list[0].league_logo = response.data[0].league_logo
            this.list[0].match = response.data
            // console.log(this.list)
          })
          .catch(error => {
            console.error(error);
          });
    },
    setActiveButton(button) {
      this.activeButton = button;
      if (button === 1) {
        this.currentPage = 'liveScore'
      } else this.currentPage = 'Lich'
    },
    formatDate(offset) {
      const targetDate = new Date(this.currentDate);
      targetDate.setDate(targetDate.getDate() + offset);
      const formatter = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/New_York'
      });
      return formatter.format(targetDate);
    },
    changeDate(date1, date2, index) {
      this.date1 = new Date(new Date().setDate(new Date().getDate() + date1)).toISOString().slice(0, 10);
      this.date2 = new Date(new Date().setDate(new Date().getDate() + date2)).toISOString().slice(0, 10);
      this.dateIndex = index

      this.fetchFootballTotal()
    },
    changeCountry(country, index) {
      this.selectedIndex = index;
      this.country = country
      this.fetchFootballTotal()
    },
    a(b) {
      console.log(b)
      // console.log(this.list)

    },
    fetchFootballCountries() {
      const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
      axios.get(`https://apiv3.apifootball.com/?action=get_countries&APIkey=${APIkey}`)
          .then(response => {
            // 假设你已经有了国家名的映射关系

            const countryMap = {
              "England": "ANH",
              "Spain": "TBN",
              "Germany": "ĐỨC",
              "Italy": "Ý",
              "France": "PHÁP",
              "Champions League": "C1",
              "Europa League": "C2",
              "Conference League": "C3",
              "Russia": "NGA",
              "V-League": "V LEAGUE",
              "Africa Cup of Nations": "CÚP CHÂU PHI",
              "Australia": "ÚC",
              "Portugal": "BĐN",
              "Major League Soccer": "MLS",
              "Japan": "NHẬT",
              "China": "CHINA",
              "Korea Republic": "HÀN",
              "Argentina": "ARGENTINA",
              "Brazil": "BRAZIL",
              "Mexico": "MEXICO",
              "Denmark": "ĐAN MẠCH",
              "Norway": "NA UY",
              "Belgium": "BỈ",
              "Switzerland": "THỤY SĨ",
              "Egypt": "AI CẬP",
              "FA Cup": "FA CUP",
              "Thailand": "THÁI",
              "Indonesia": "INDO",
              "Bundesliga": "BUNDESLIGA 2",

              // ... 其他国家的映射关系
            };

// 假设接口返回的数组为countries
            const mappedCountries = response.data.map(country => {
              return {
                country_id: country.country_id,
                country_name: country.country_name,
                country_name2: countryMap[country.country_name] || "", // 使用映射关系添加新字段
                // 其他字段
              };
            });
            // console.log('aaaaa',mappedCountries)
            this.countries = mappedCountries;

          })
          .catch(error => {
            console.error(error);
          });
    },
    loadMore() { // 加载更多按钮点击事件处理函数
      if (this.loadedItems >= this.total.length) return; // 如果已加载的数量等于或超过原始数据数组长度，则停止加载
      this.loadedItems += this.chunkSize; // 增加已加载数量
      if (this.loadedItems > this.total.length) {
        this.loadedItems = this.total.length;
      }   // 如果超过了原始数据数组长度，则将已加载数量设为数组长度，避免超出范围

    },

    async fetchFootballTotal() {
      let success = false;
      while (!success) {
        try {
          this.loading = true
          this.list = []
          const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
          const response = await axios.get(`https://apiv3.apifootball.com/?action=get_leagues&country_id=${this.country}&APIkey=${APIkey}`)
          const list = response.data;

          await Promise.all(list.map(async item => {
            const league_id = item.league_id;
            const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&from=${this.date1}&to=${this.date2}&league_id=${league_id}&APIkey=${APIkey}`);
            item.match = detailsResponse.data;

          }));
          this.list = list;
          // console.log('All data with details:', this.list);
          success = true;
          this.loading = false
          // 在这里进行处理和渲染详细数据
        } catch (error) {
          console.error(error);
          // 如果接口调用失败，则等待一段时间后重新调用
          await new Promise(resolve => setTimeout(resolve, 1000)); // 等待1秒
        }
      }
    },

    generateLeagues() {
      this.total.forEach(match => {
        const league_name = match.league_name;
        const foundLeague = this.leagues.find(league => league.league_name === league_name);
        if (!foundLeague) {
          this.leagues.push({
            league_name: league_name,
            league_logo: match.league_logo,
            matches: [match]
          });
        } else {
          foundLeague.matches.push(match);
        }
      });
      console.log(this.leagues, 'total data')
    },
    async fetchFootballLiveScore() {
      try {
        this.total = [];
        const APIkey = 'c946240ef9ad6bd2dd78679753b100ac9787cb1a148dfc82d6f7dfb133b04ebd';
        // const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10);
        // const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        const today = new Date().toISOString().slice(0, 10);
        const response = await axios.get(`https://apiv3.apifootball.com/?action=get_odds&from=${today}&to=${today}&odd_bookmakers=Marathon&APIkey=${APIkey}`);
        const total = response.data;
        await Promise.all(total.map(async item => {
          const id = item.match_id;
          const detailsResponse = await axios.get(`https://apiv3.apifootball.com/?action=get_events&match_id=${id}&APIkey=${APIkey}`);
          const details = detailsResponse.data[0];
          item.match_date = details.match_date;
          item.match_time = details.match_time;
          item.league_name = details.league_name;
          item.match_awayteam_halftime_score = details.match_awayteam_halftime_score

          item.match_hometeam_halftime_score = details.match_hometeam_halftime_score
          item.match_hometeam_score = details.match_hometeam_score
          item.match_awayteam_score = details.match_awayteam_score
          item.match_hometeam_name = details.match_hometeam_name
          item.match_awayteam_name = details.match_awayteam_name
          item.league_logo = details.league_logo
          item.team_home_badge = details.team_home_badge
          item.team_away_badge = details.team_away_badge
          item.match_hometeam_name = details.match_hometeam_name;
          item.match_awayteam_name = details.match_awayteam_name;
        }));
        this.total = total;
        console.log('All data with details:', this.total);
        this.generateLeagues()

        // 在这里进行处理和渲染详细数据
      } catch (error) {
        console.error(error);
      }
    },
  }
};

</script>
<style scoped lang="scss">
@media (min-width: 700px) {
  .loading {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -20px; /* 将加载动画水平居中 */
    margin-top: -20px; /* 将加载动画垂直居中 */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .body {
    //height: 10000px;
    background-color: #232324;
    margin-top: -1%;
  }
  .green {
    background-color: green;
  }
  .top {
    .matchBox {
      .matchBox-title {
        margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 50px;

        .matchBox-title-left {
          margin-left: 1%;
          width: 50%;
          justify-content: flex-start;
        }

        .matchBox-title-center {
          margin-left: 1%;
          width: 50%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 33%;
          }
        }

        .matchBox-title-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 25%;
        }
      }

      .matchBox-container {
        margin-left: -1%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;

        height: 65px;

        .matchBox-container-left {
          margin-left: 1%;
          width: 30%;
        }

        .matchBox-container-center {
          margin-left: 1%;
          width: 55%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 20%;
            height: 30px;
          }
        }

        .matchBox-container-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 15%;

          div {
            width: 30%;
          }
        }
      }
    }

    width: 85%;
    margin: auto;
    left: 50%;

    .container {
      margin-top: 2%;
      background: #1c1e1f;
      margin-left: -1%;
      max-width: 100%;
      width: 100%;
      //border: blue solid 1px;

      .containerTop {
        height: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        display: inline-flex; /* 使用inline-flex布局 */
        .item {
          font-size: 16px; /* 设置更大的字体大小 */
          cursor: pointer;
          margin: 7px; /* 设置一些外边距 */
          //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
          text-align: center; /* 居中文本 */

        }
      }
    }
  }

  .top1 {
    color: white;
    padding-top: 3%;

  }


  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .loading {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -20px; /* 将加载动画水平居中 */
    margin-top: -20px; /* 将加载动画垂直居中 */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .body {
    height: 10000px;
    background-color: #232324;
    margin-top: -1%;
  }
  .green {
    background-color: green;
  }
  .top {
    .matchBox {
      .matchBox-title {
        margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 40px;
        font-size: 12px;

        .matchBox-title-left {
          margin-left: 1%;
          width: 100%;
          justify-content: flex-start;
          font-size: 12px;
        }

        .matchBox-title-center {
          font-size: 12px;
          margin-left: 1%;
          width: 70%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 25%;
          }
        }

        .matchBox-title-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 10%;
        }
      }

      .matchBox-container {
        margin-left: -1%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        height: 65px;

        .matchBox-container-left {
          font-size: 10px;
          margin-left: 1%;
          width: 25%;
        }

        .matchBox-container-center {
          margin-left: 1%;
          width: 68%;
          display: flex;
          justify-content: center;

          div {
            height: 25px;
            display: flex;
            justify-content: center;
            width: 15%;
          }
        }

        .matchBox-container-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 7%;

          div {
            width: 60%;
          }
        }
      }
    }

    width: 95%;
    margin: auto;
    left: 50%;

    .container {
      margin-top: 2%;
      background: #1c1e1f;
      margin-left: -1%;
      max-width: 100%;
      width: 100%;
      //border: blue solid 1px;

      .containerTop {
        height: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        display: inline-flex; /* 使用inline-flex布局 */
        .item {

          cursor: pointer;
          margin: 7px; /* 设置一些外边距 */
          //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
          text-align: center; /* 居中文本 */

        }
      }
    }
  }

  .top1 {
    color: white;
    padding-top: 3%;

  }


  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}

.sports-bars {
  border-radius: 6px;
  background: #1C1E20;
  padding: 8px 10px;
}

.sports-bars .nav-tabs {
  border-bottom: 0;
}

.sports-bars .nav-tabs .nav-item .nav-link {
  margin-bottom: 0;
  background: none;
  border: none;
  gap: 8px;
}

.sports-bars .nav-tabs .nav-item .nav-link.active, .sports-bars .nav-tabs .nav-item .nav-link:focus {
  border-radius: 6px;
  background: #00A942;
  border: none;
  color: #fff;
  outline: none;
}

.sports-bars .nav-tabs .nav-item span {
  line-height: 1;
}

.sports-bars form input[type="text"] {
  border-radius: 6px;
  background: #2A2C2F;
  color: #B5BEC9;
  font-family: 'Raleway';
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  border: none;
  height: 2rem;
  padding: 0.44rem 0.62rem;
}

.sports-bars form button {
  background: linear-gradient(86deg, #009B3A -8.29%, #009B3A 62.2%, #00BD47 102.14%);
  color: #FFF;
  font-family: 'Raleway';
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  border: none;
  border-radius: 6px;
  height: 2rem;
  margin-left: 2px;
  padding: 0.44rem 0.75rem;
}

pre {
  background: #fff;
}

@media (max-width: 700px) {
  .block2 {
    width: 100%;
    display: flex;
    align-items: center;

    div {
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33.3%;
    }
  }
}

.block2 {
  width: 100%;
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
}

.odds1 {
  display: flex;
  align-items: center;

  div {
    width: 30%;
  }
}

@media (min-width: 700px) {
  .loading {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -20px; /* 将加载动画水平居中 */
    margin-top: -20px; /* 将加载动画垂直居中 */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .body {
    //height: 20000px;
    background-color: #232324;
    margin-top: -1%;
  }
  .green {
    background-color: green;
  }
  .top {
    .matchBox2 {
      .matchBox-league {
        div {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 30px;
          }
        }

        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #1C1E20;
        display: flex;
        height: 50px;
      }

      .matchBox-title {
        //margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 50px;

        .matchBox-title-left {
          margin-left: 1%;
          width: 10%;
          justify-content: flex-start;
        }

        .matchBox-title-center {
          margin-left: 1%;
          width: 60%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 33%;
          }
        }

        .matchBox-title-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 10%;
        }

        .matchBox-title-odds {
          //margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 20%;
        }
      }

      .matchBox-container {
        margin-left: -1%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;

        height: 65px;

        .matchBox-container-left {
          margin-left: 1%;
          width: 10%;
        }

        .matchBox-container-center {
          margin-left: 1%;
          width: 60%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 15%;
            height: 30px;
          }
        }

        .matchBox-container-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 10%;

          div {
            width: 40%;
          }
        }

        .matchBox-container-odds {
          width: 20%;
        }
      }
    }

    width: 85%;
    margin: auto;
    left: 50%;

    .container {
      margin-top: 2%;
      background: #1c1e1f;
      margin-left: -1%;
      max-width: 100%;
      width: 100%;
      //border: blue solid 1px;

      .containerTop {
        height: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        display: inline-flex; /* 使用inline-flex布局 */
        .item {
          font-size: 16px; /* 设置更大的字体大小 */
          cursor: pointer;
          margin: 7px; /* 设置一些外边距 */
          //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
          text-align: center; /* 居中文本 */

        }
      }
    }
  }

  .top1 {
    color: white;
    padding-top: 3%;

  }


  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .loading {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 120%;
    left: 50%;
    margin-left: -20px; /* 将加载动画水平居中 */
    margin-top: -20px; /* 将加载动画垂直居中 */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .body {
    height: 10000px;
    background-color: #232324;
    margin-top: -1%;
  }
  .green {
    background-color: green;
  }
  .top {
    .matchBox2 {
      .matchBox-league {
        div {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 30px;
          }
        }

        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #1C1E20;
        display: flex;
        height: 50px;
      }
      .matchBox-title {
        margin-left: -1%;
        margin-top: 2%;
        align-items: center;
        width: 100%;
        background-color: #292b2e;
        display: flex;
        height: 50px;
        font-size: 12px;

        .matchBox-title-left {
          margin-left: 1%;
          width: 7%;
          justify-content: flex-start;
          font-size: 12px;
        }

        .matchBox-title-center {
          font-size: 12px;
          margin-left: 1%;
          width: 55%;
          display: flex;
          justify-content: center;

          div {
            display: flex;
            justify-content: center;
            width: 25%;
          }
        }

        .matchBox-title-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 15%;
        }

        .matchBox-title-odds {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 23%;
        }
      }

      .matchBox-container {
        margin-left: -1%;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 10px;
        height: 65px;

        .matchBox-container-left {
          font-size: 10px;
          margin-left: 1%;
          width: 7%;
        }

        .matchBox-container-center {
          margin-left: 1%;
          width: 55%;
          display: flex;
          justify-content: center;

          div {
            height: 25px;
            display: flex;
            justify-content: center;
            width: 15%;
          }
        }

        .matchBox-container-right {
          margin-left: 1%;
          display: flex;
          justify-content: center;
          width: 15%;

          div {
            width: 60%;
          }

        }

        .matchBox-container-odds {
          width: 23% !important;
        }
      }
    }

    width: 97%;
    margin: auto;
    left: 50%;

    .container {
      margin-top: 2%;
      background: #1c1e1f;
      margin-left: -1%;
      max-width: 100%;
      width: 100%;
      //border: blue solid 1px;

      .containerTop {
        height: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        display: inline-flex; /* 使用inline-flex布局 */
        .item {

          cursor: pointer;
          margin: 7px; /* 设置一些外边距 */
          //border: 1px solid #000; /* 可以添加边框以便查看每个元素的边界 */
          text-align: center; /* 居中文本 */

        }
      }
    }
  }

  .top1 {
    color: white;
    padding-top: 3%;

  }


  #wap_bottombanner {
    height: auto !important;
    bottom: 44px;
  }

  #wap_bottombanner a {
    display: block;
  }

  #wap_bottombanner br {
    display: none;
  }

  #wap_bottombanner_content .mb-1 {
    margin-bottom: 0px !important;
  }

  .menu_bottom {
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background: #292B2E;
    z-index: 999;

  }

  .menu_bottom ul {
    height: 2.75rem;
    display: flex;
    align-content: center;
  }

  .menu_bottom ul li {
    flex: 1;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .menu_bottom ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    color: #FFF;
    text-align: center;
    font-family: 'Raleway';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1; /* 140% */
    gap: 0.12rem;
    text-transform: uppercase;
  }

  .menu_bottom ul li:hover, .menu_bottom ul li:focus {
    background: linear-gradient(102deg, #008360 4.83%, #1BB916 118.99%);
  }

  .menu_bottom ul li a svg {
    display: block;
    margin-bottom: 5px;
  }

  .menu_bottom ul li a svg path {
    fill: #fff;
  }

  .footer-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 728px;
    margin: 0px auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  #menu-footer a {
    color: #fff;
  }

  .footer-banner p {
    margin-bottom: 5px !important;
  }

  .close-ads {
    font-size: 12px;
    position: absolute;
    bottom: 100%;
    right: -2px;
    border-radius: 0px;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #FFF;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textwidget p {
    margin-bottom: 5px;
  }

  .menu-cuoc-one88 a, .menu-cuoc-fabet a {
    background: #ffc107;
    color: #000 !important;
  }

  .menu-top-nha-cai a {
    border-radius: 3px;
    background: linear-gradient(142deg, #BB5014 0%, #8B0000 100%);
  }

  @media screen and (min-width: 992px) {
    #header .block-int img, .main .block-int img {
      height: 60px;
      width: 100%;
    }
  }
}

</style>
