import axios from '@/axios';

const AxiosPlugin = {
    install(Vue) {
        // 将Axios实例挂载到Vue原型上，使其在所有组件中都可以使用
        Vue.prototype.$axios = axios;
    }
};

export default AxiosPlugin;
