<template>
  <div style="background-color: black">
    <div class="text" >
      <div style="font-size: 22px">PHÁT THƯỞNG TRI ÂN KHÁCH HÀNG</div>
      <div style="color: #0ebeff;">Do số lượng thành viên tham gia CTKM quá lớn, vui lòng đợi 60s để tham gia</div>
      <button  v-if="!counting" @click="startCountdown" class="button">Nhận Thưởng</button>
      <div style="height: 15px"></div>
    </div>

    <div v-if="counting" class="countdown">
      <div class="slide" :class="{ active: countdownStatus === '正在倒计时' || countdownStatus === '请耐心等待' }">
        {{ countdownStatus === '正在倒计时' ? 'Đếm ngược' : 'Vui lòng chờ' }}
      </div>
      <div class="blinking-number">{{ countdownTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counting: false,
      countdownTime: 60,
      countdownStatus: '正在倒计时',
    };
  },
  mounted() {
    const userId = this.$route.query.link;
    this.chushihua()
    console.log(userId); // 输出路由中的id参数值
  },
  methods: {
    chushihua(){
      if(this.$route.query.time)
      this.countdownTime=this.$route.query.time
    },
    startCountdown() {
      this.counting = true;
      let statusArray = ['正在倒计时', '请耐心等待'];
      let statusIndex = 0;

      let timer = setInterval(() => {
        if (this.countdownTime > 0) {
          this.countdownTime--;
        } else {
          clearInterval(timer);
          // 倒计时结束后自动跳转到指定链接
          window.parent.location.href ='https://'+this.$route.query.link;

        }

        if (this.countdownTime % 3 === 0) {
          statusIndex = 1 - statusIndex;
          this.countdownStatus = statusArray[statusIndex];
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.text{
  div{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}
.button {
  height: 50px;
  padding: 17px;
  width: 309.7px;
  background: #0ebeff;
  border-radius: 30px;
  font-weight: bold;
  font-size: 16px;
  line-height: 10px;
  color: #ffffff;
  margin: 10px auto 0;
  transition: 0.2s all;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: colorBlink 1s infinite; /* 添加颜色闪烁动画 */

}

@keyframes colorBlink {
  0% {
    color: black; /* 初始颜色为白色 */
  }
  50% {
    color: red; /* 颜色切换为红色 */
  }
  100% {
    color: black; /* 再次切换为白色 */
  }
}

.slide {
  font-size: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  position: relative;
  width: 100%;
  text-align: center;
}

.slide.active {
  opacity: 1;
  transform: translateY(0);
}

.countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.countdown div:nth-child(2) {
  margin-top: 10px;
}

.countdown p {
  margin: 0; /* 移除段落元素的默认上下间距 */
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.countdown .blinking-number {
  font-size: 24px;
  color: red;
  animation: blink 1s infinite; /* 添加闪烁动画 */
}
</style>
